import { apiAthenas } from '@/services/api/apiAthenas'

type ConfirmUploadFilesProps = {
  demandId: string
  partId: string
  fileType: string
  listOfFileNames: string[]
}

export async function confirmPartResultUpload({
  demandId,
  partId,
  fileType,
  listOfFileNames
}: ConfirmUploadFilesProps) {
  await apiAthenas.post(
    `demands/${demandId}/parts/${partId}/file/${fileType}/confirm-upload`,
    { listOfFileNames }
  )
}
