import { useMutation } from '@tanstack/react-query'

import { handleToast } from '@/utils/handleToast'

import { useParams } from 'react-router-dom'

import { downloadWeedResult } from '@/services/modules/athenas/demands/download-weed-result'
import { FileType } from '@/types/fileTypes'

export function useDownloadWeedResult() {
  const { id } = useParams()

  const mutation = useMutation({
    mutationFn: async ({
      fileType,
      userId
    }: {
      fileType: FileType
      userId: number
    }) => {
      const { data } = await downloadWeedResult({
        demandId: id!,
        userId,
        fileType
      })

      const anchorElement = document.createElement('a')
      anchorElement.setAttribute('download', 'title')

      anchorElement.href = data
      anchorElement.click()
    },
    onError: () =>
      handleToast({
        type: 'error',
        message: 'Não foi possível realizar o download. Tente novamente.'
      })
  })

  return mutation
}
