import { updateDiscordId } from '@/services/modules/athenas/users/update-discord-id'
import { handleToast } from '@/utils/handleToast'
import { useQuery } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { useEffect } from 'react'

import { useDebounce } from 'use-debounce'

export function useUpdateDiscordId({
  discordId,
  userId
}: {
  userId: number
  discordId: string
}) {
  const [debounceDiscordId] = useDebounce(discordId, 2000)

  const query = useQuery<any, AxiosError>({
    queryKey: ['UPDATE_DISCORD_ID', { userId }, debounceDiscordId],
    queryFn: () => updateDiscordId({ discordId: debounceDiscordId, userId }),

    enabled: !!debounceDiscordId
  })

  const messageError =
    query.error?.response?.status === 409
      ? 'Este User ID já foi cadastrado por outra pessoa na Athenas. Entre em contato com a equipe de desenvolvimento.'
      : 'Não foi possível adicionar/alterar o User ID do discord. Tente novamente'

  useEffect(() => {
    if (query.isError) {
      handleToast({
        type: 'error',
        message: messageError
      })
    }
  }, [query.isError, query.error, messageError])

  return query
}
