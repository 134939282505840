import { LoadingCircle } from '@/components'

export function ProcessingDialog() {
  return (
    <div className="z-10 flex min-h-screen items-center justify-center">
      <div className="fixed inset-0  flex items-center justify-center bg-black/50">
        <div className="h-[204px] w-[432px] rounded-[28px] bg-cromai-m3-sys-light-surface-variant  p-6 dark:bg-cromai-m3-sys-dark-surface-variant ">
          <h2 className="text-titleLarge text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface">
            Aguarde enquanto as partes são criadas
          </h2>
          <p className=" mt-4 text-bodyLarge text-cromai-m3-sys-light-on-surface-variant dark:text-cromai-m3-sys-dark-on-surface-variant">
            Quando o processo terminar, esta página será atualizada. Você não
            precisa fazer nada enquanto isso, é só relaxar.
          </p>
          <LoadingCircle className="mx-auto mt-6" size={24} />
        </div>
      </div>
    </div>
  )
}
