import * as DialogRadix from '@radix-ui/react-dialog'

import { Button, Dialog } from '@/components'
import { useDeleteComment } from '@/hooks/demands/mutations/useDeleteComment'

type DialogDeleteComment = {
  name: string
  commentId: string
  setIsOpen: (value: boolean) => void
  isOpen: boolean
}

export function DialogDeleteComment({
  name,
  commentId,
  setIsOpen,
  isOpen
}: DialogDeleteComment) {
  const { mutate: deleteComment } = useDeleteComment()

  function handleDeleteComment() {
    deleteComment({ commentId })

    setIsOpen(false)
  }

  return (
    <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="w-[450px] p-8">
        <DialogRadix.Title
          aria-describedby="test"
          className="font-mono text-titleMedium text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface"
        >
          {`Você tem certeza que deseja remover o comentário de `}
          <strong>{name}</strong>
          {` desta demanda?`}
        </DialogRadix.Title>
        <DialogRadix.Description className="hidden">
          Deletar comentario de {name}
        </DialogRadix.Description>

        <div className="mt-8 flex w-full justify-between">
          <Button onClick={() => setIsOpen(false)} variant="outlined">
            Cancelar
          </Button>
          <Button onClick={handleDeleteComment}>Apagar</Button>
        </div>
      </div>
    </Dialog>
  )
}
