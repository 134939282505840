import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

import { Button, Input } from '@/components'
import { TagColorsMenu } from './TagColorsMenu'
import { TagConfig } from './TagConfig'

import { Colors } from '@/types/Colors'
import { SummaryBoards } from '@/services/modules/athenas/boards/get-summary-boards'

import { useCreateTag } from '@/hooks/boards/mutations/useCreateTag'

const tagSchema = z.object({
  name: z.string()
})

type TagSchema = z.infer<typeof tagSchema>

type TagsProps = {
  summaryBoard?: SummaryBoards
}

export function Tags({ summaryBoard }: TagsProps) {
  const [color, setColor] = useState<Colors>('turquoise')

  const { mutate: createTag, isLoading: isCreateTag } = useCreateTag()

  const { register, handleSubmit, reset } = useForm<TagSchema>({
    resolver: zodResolver(tagSchema)
  })

  const onSubmit = handleSubmit((data, event) => {
    event?.preventDefault()
    createTag({ name: data.name, color, boardId: summaryBoard!.id })
    reset()
  })

  return (
    <div className="mt-6 text-cromai-m3-sys-light-on-background dark:text-cromai-m3-sys-dark-on-background">
      <h1 className="mb-2 text-titleMedium">Etiquetas</h1>
      <p className="mb-4 w-[432px] font-mono text-bodySmall ">
        Crie as etiquetas para ajudar na organizar das demandas no quadro. Ao
        excluir uma etiqueta, essa etiqueta será removida de todas as demandas
        do quadro.
      </p>
      <form onSubmit={onSubmit} className="flex items-center gap-4">
        <Input
          required
          className="h-[52px] w-52"
          placeholder="Etiqueta"
          aria-label="Nome da etiqueta"
          {...register('name', { required: true })}
        >
          <TagColorsMenu setColor={(value) => setColor(value)} color={color} />
        </Input>

        <Button
          loading={isCreateTag}
          disabled={!summaryBoard}
          variant="outlined"
          className="w-[152px]"
        >
          Adicionar etiquetas
        </Button>
      </form>

      <div className="mr-2 h-[calc(100vh-25rem)] overflow-auto pr-2">
        {summaryBoard?.tags?.map((tag, index) => {
          return (
            <TagConfig
              key={tag.id}
              boardId={summaryBoard!.id}
              tagId={tag.id}
              tagName={tag.name}
              tagColor={tag.color}
              tagIndex={index}
              indexLastItem={summaryBoard.tags.length - 1}
            />
          )
        })}
      </div>
    </div>
  )
}
