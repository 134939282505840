import { twMerge } from 'tailwind-merge'
import { TableValues } from './TableValues'
import { useGetAiData } from '@/hooks/demands/queries/useGetAiData'
import { LoadingCircle } from '@/components'

export function AiDataTable() {
  const { data: table, isLoading } = useGetAiData()

  if (isLoading) {
    return (
      <div className="flex size-full items-center justify-center">
        <LoadingCircle size={30} />
      </div>
    )
  }

  return (
    <section className="size-full overflow-auto pr-4">
      <table className="w-full pr-4">
        <tbody>
          {table?.map((data, index) => {
            const isEven = index % 2 === 0
            return (
              <tr
                key={data.name}
                className={twMerge(
                  data.isBold ? 'text-labelMedium' : 'text-bodySmall',
                  isEven && 'bg-[#CBD1C7] dark:bg-[#505A50]',
                  'flex h-6 items-center'
                )}
              >
                <td className="w-[200px] shrink-0 truncate pl-1 text-start">
                  {data.name}
                </td>

                <TableValues {...data.value} />
              </tr>
            )
          })}
        </tbody>
      </table>
    </section>
  )
}
