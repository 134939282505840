import { apiAthenas } from '@/services/api/apiAthenas'
import { FailureParts } from '@/types/Parts'

export async function getFailureParts(demandId: string) {
  const { data } = await apiAthenas.get<FailureParts>(
    `demands/${demandId}/failureParts`
  )

  return data
}
