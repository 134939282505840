import { twMerge } from '@/styles/tailwindMergeConfig'
import * as Progress from '@radix-ui/react-progress'

type ProgressBarProps = {
  progress: number
}

export function ProgressBar({ progress }: ProgressBarProps) {
  function getProgressColor(progress: number) {
    if (progress < 40) return 'bg-[#D41851]'
    if (progress < 70) return 'bg-[#F48B3E]'
    if (progress < 100) return 'bg-[#4DB5E9]'
    return 'bg-[#C9F170]'
  }

  return (
    <Progress.Root
      className={twMerge(
        'overflow-hidden',
        'h-1 w-[50px] transform rounded-full bg-cromai-m3-sys-light-surface-variant dark:bg-cromai-m3-sys-dark-surface-variant'
      )}
    >
      <Progress.Indicator
        data-testid="progress-indicator"
        className={twMerge(
          getProgressColor(progress),
          'size-full',
          'ease-[cubic-bezier(0.65, 0, 0.35, 1)] transition-transform duration-[660ms]'
        )}
        style={{ transform: `translateX(-${100 - progress}%)` }}
      />
    </Progress.Root>
  )
}
