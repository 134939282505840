import { Avatar } from '@/components'
import { useAuth } from '@/context/auth'
import { useTheme } from '@/context/theme'

import * as Popover from '@radix-ui/react-popover'

import {
  MdLogout,
  MdOutlineDarkMode,
  MdOutlineLightMode,
  MdOutlineSettingsSuggest,
  MdPerson
} from 'react-icons/md'

import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

export function AccountMenu() {
  const { themeConfig, setThemeConfig } = useTheme()

  const { signOut, user } = useAuth()

  return (
    <Popover.Root>
      <Popover.Trigger>
        <Avatar name={user.name} />
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          align="start"
          side="top"
          alignOffset={40}
          className={twMerge(
            'mt-6 h-[196px] w-[292px] rounded-md font-mono',
            'bg-cromai-m3-sys-light-surface2',
            'dark:bg-cromai-m3-sys-dark-surface2',
            'shadow-lightElevation2'
          )}
        >
          <div className="w-full rounded-md bg-cromai-m3-sys-light-inverse-on-surface pt-4 dark:bg-cromai-m3-sys-dark-inverse-on-surface">
            <h1 className="px-5 text-labelLarge text-cromai-m3-sys-light-inverse-surface dark:text-cromai-m3-sys-dark-inverse-surface">
              {user.name}
            </h1>
            <span className="px-5 text-labelMedium text-cromai-m3-sys-light-outline dark:text-cromai-m3-sys-dark-outline">
              {user.email}
            </span>
            <div
              className="ml-4 mt-4 inline-flex h-8 w-[264px] justify-between rounded-md shadow-sm "
              role="group"
            >
              <button
                title="Sistema"
                onClick={() => setThemeConfig('system')}
                className={twMerge(
                  'flex w-full items-center justify-center rounded-l-lg border py-2 text-sm',
                  'border-cromai-m3-sys-light-outline dark:border-cromai-m3-sys-dark-outline',
                  'transition-all hover:opacity-80',
                  `${
                    themeConfig === 'system' &&
                    'bg-cromai-m3-sys-light-surface-variant dark:bg-cromai-m3-sys-dark-surface-variant'
                  }`
                )}
              >
                <MdOutlineSettingsSuggest
                  size={12}
                  className="text-cromai-m3-sys-light-on-background dark:text-cromai-m3-sys-dark-on-background"
                />
              </button>
              <button
                title="Claro"
                onClick={() => setThemeConfig('light')}
                className={twMerge(
                  'flex w-full items-center justify-center border-y py-2 text-sm ',
                  'border-cromai-m3-sys-light-outline dark:border-cromai-m3-sys-dark-outline',
                  'transition-all hover:opacity-80',
                  `${
                    themeConfig === 'light' &&
                    'bg-cromai-m3-sys-light-surface-variant dark:bg-cromai-m3-sys-dark-surface-variant'
                  }`
                )}
              >
                <MdOutlineLightMode
                  size={12}
                  className="text-cromai-m3-sys-light-on-background dark:text-cromai-m3-sys-dark-on-background"
                />
              </button>
              <button
                title="Escuro"
                onClick={() => setThemeConfig('dark')}
                className={twMerge(
                  'flex w-full items-center justify-center py-2',
                  'rounded-r-md border text-sm',
                  'transition-all hover:opacity-80',
                  'border-cromai-m3-sys-light-outline dark:border-cromai-m3-sys-dark-outline',
                  `  ${
                    themeConfig === 'dark' &&
                    'bg-cromai-m3-sys-light-surface-variant dark:bg-cromai-m3-sys-dark-surface-variant'
                  }`
                )}
              >
                <MdOutlineDarkMode
                  size={12}
                  className="text-cromai-m3-sys-light-on-background dark:text-cromai-m3-sys-dark-on-background"
                />
              </button>
            </div>
            <Link
              to={'/profile-settings'}
              className={twMerge(
                'flex w-full items-center gap-3 px-5 py-2 pt-4 text-labelMedium transition-opacity hover:opacity-80',
                'text-cromai-m3-sys-light-on-surface-variant dark:text-cromai-m3-sys-dark-on-surface-variant'
              )}
            >
              <MdPerson size={18} />
              Perfil Athenas
            </Link>
          </div>
          <button
            onClick={signOut}
            className={twMerge(
              'flex h-[40px] w-[292px] items-center gap-3 px-5 py-[10px] font-semibold',
              'transition-all hover:opacity-80',
              'text-cromai-m3-sys-light-on-background dark:text-cromai-m3-sys-dark-on-background'
            )}
          >
            <MdLogout
              size={18}
              className="text-cromai-m3-sys-light-on-background dark:text-cromai-m3-sys-dark-on-background"
            />
            Sair
          </button>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}
