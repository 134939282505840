import { LoadingCircle } from '@/components'
import { twMerge } from '@/styles/tailwindMergeConfig'

export function Fallback() {
  return (
    <div className={twMerge('flex h-screen items-center justify-center')}>
      <LoadingCircle size={20} />
    </div>
  )
}
