import { useDownload } from '@/hooks/useDownload'
import { useGetDemand } from '@/hooks/useGetDemand'

export function Links() {
  const { data } = useGetDemand()

  const { mutate: download } = useDownload()

  return (
    <div className="flex w-full flex-col items-start gap-4 overflow-auto">
      {data!.files?.map((file) => {
        const dataForDownload = {
          id: data!.id,
          key: file.key,
          value: file.value
        }
        return (
          <button
            onClick={() => download(dataForDownload)}
            key={file.key}
            className="px-6 py-2 text-labelSmall text-cromai-m3-sys-light-on-surface-variant transition-all hover:opacity-80 dark:text-cromai-m3-sys-dark-on-surface-variant"
          >
            {file.label}
          </button>
        )
      })}
    </div>
  )
}
