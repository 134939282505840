import { twMerge } from 'tailwind-merge'
import { UnitPart } from './UnitPart'
import { DownloadMenu } from './DownloadMenu'

import { Part as PartType } from '@/types/Parts'

type PartProps = PartType

export function Part({
  id,
  area,
  partNumber,
  lineReconstruction,
  plantingFailures,
  downloadFiles
}: PartProps) {
  const isResults =
    !!lineReconstruction.fileList.length || !!plantingFailures.fileList.length

  return (
    <section
      className={twMerge(
        'mb-2 flex w-full min-w-[655px] shrink-0 flex-grow items-center justify-between gap-5 rounded-lg p-2 font-mono text-bodyMedium',
        'bg-cromai-m3-sys-light-surface dark:bg-cromai-m3-sys-dark-surface'
      )}
    >
      <div className="flex items-center gap-4">
        <DownloadMenu
          partId={id}
          partNumber={partNumber}
          isResults={isResults}
          downloadFiles={downloadFiles}
        />

        <span className="w-[22px] text-end text-bodyMedium">{partNumber}</span>
        <span className="w-[60px] text-end text-labelLarge">
          {area.toFixed(1)} ha
        </span>
      </div>
      <div className="flex gap-6">
        <UnitPart
          area={area}
          partId={id}
          partNumber={partNumber}
          fileType="reconstruction_lines"
          {...lineReconstruction}
        />
        <UnitPart
          area={area}
          partId={id}
          partNumber={partNumber}
          fileType="planting_failure"
          {...plantingFailures}
        />
      </div>

      <div className="size-6" />
    </section>
  )
}
