import { useState } from 'react'
import { TabOptions } from '../TabOptions'
import { Links } from './Links'
import { History } from './History'
import { twMerge } from '@/styles/tailwindMergeConfig'
import { Comments } from './Comments'
import { AiDataTable } from './AiDataTable'
import { AtlasDataTable } from './AtlasDataTable'
import { useSwitchByTreatment } from '../hooks/useSwitchTreatment'
import { WeedParts } from './Parts/WeedParts'
import { FalilureParts } from './Parts/FailureParts'

const parts = {
  weed: WeedParts,
  planting_failure: FalilureParts
}

export function TabsLeft() {
  const [option, setOption] = useState('parts')
  const Parts = useSwitchByTreatment(parts)

  return (
    <div className="flex flex-1 flex-col items-start gap-2">
      <TabOptions
        defaultValue="parts"
        ariaLabel="abs-left-options"
        options={[
          'parts',
          'links',
          'atlasData',
          'aiData',
          'comments',
          'history'
        ]}
        setSelectedOption={setOption}
        selectedOption={option}
      />
      <div
        className={twMerge(
          'flex min-h-[400px] w-full flex-col items-start gap-4 rounded p-[18px] font-mono text-labelLarge lg:h-full',
          'bg-cromai-m3-sys-light-surface-variant text-cromai-m3-sys-light-on-background',
          'dark:bg-cromai-m3-sys-dark-surface-variant dark:text-cromai-m3-sys-dark-on-background'
        )}
      >
        {option === 'parts' && <Parts />}
        {option === 'links' && <Links />}
        {option === 'comments' && <Comments />}
        {option === 'history' && <History />}
        {option === 'aiData' && <AiDataTable />}
        {option === 'atlasData' && <AtlasDataTable />}
      </div>
    </div>
  )
}
