import { twMerge } from '@/styles/tailwindMergeConfig'
import { useState } from 'react'
import { RevisionTime } from './RevisionTime'
import { Responsible } from './Responsible'
import { TabOptions } from '../TabOptions'

export function TabsRight() {
  const [option, setOption] = useState('responsibles')

  return (
    <div
      className={twMerge(
        'lg:w-[420px]',
        'flex flex-1 flex-col gap-2 lg:flex-none'
      )}
    >
      <TabOptions
        ariaLabel="tabs-right-options"
        defaultValue="responsibles"
        options={['responsibles', 'revision-time']}
        selectedOption={option}
        setSelectedOption={setOption}
      />
      {option === 'responsibles' && <Responsible />}
      {option === 'revision-time' && <RevisionTime />}
    </div>
  )
}
