import { Comment } from './Comment'

import { useGetDemandComments } from '@/hooks/demands/queries/useGetDemandComments'

import { Skeleton } from './Skeleton'
import { CommentForm } from './CommentForm'

export function Comments() {
  const {
    data: comments,
    isLoading: isLoadingComments,
    isError: isErrorComments
  } = useGetDemandComments()

  if (isErrorComments || isLoadingComments) {
    return <Skeleton isLoading={isLoadingComments} />
  }

  return (
    <section className="flex size-full flex-col gap-4">
      <div className="flex max-h-[calc(100%-64px)] flex-col gap-2 overflow-auto">
        {comments?.map((comment, index) => {
          return (
            <Comment
              key={comment.id}
              comment={comment.message}
              userId={comment.userId}
              commentId={comment.id}
              createdAt={comment.createdAt}
              updatedAt={comment.updatedAt}
              commentIndex={index}
            />
          )
        })}
      </div>
      <CommentForm />
    </section>
  )
}
