import ScrollContainer from 'react-indiana-drag-scroll'

import { differenceInHours } from 'date-fns'
import { useGetBoard } from '@/hooks/boards/useGetBoard'

import { BoardSkeletons } from './BoardSkeletons'
import { Column } from './Column'

import { useGetTotalArea } from './hooks/UseGetTotalArea'
import { percentage } from '@/utils/percentage'
import { Navbar } from './Navbar'

export function Board() {
  const { data, isLoading, isError } = useGetBoard()
  const { getTotalArea } = useGetTotalArea()

  if (isLoading || isError) {
    return <BoardSkeletons loading={isLoading} />
  }

  const allCards = data.columns.flatMap((column) => column.cards)

  const allCardsNotConclued = allCards.filter(
    (card) => card.status.value !== 'concluded'
  )

  const totalBoardArea = getTotalArea(allCardsNotConclued)

  const columns = data.columns.map((column) => {
    const filteredCards = column.cards.filter((card) => {
      const isConclued = card.status.value === 'concluded'
      const isConcludedAt = card.concludedAt

      return isConclued && isConcludedAt
        ? differenceInHours(new Date(), isConcludedAt) < 24
        : true
    })

    return { ...column, cards: filteredCards }
  })

  return (
    <main className="flex size-full  flex-col items-center justify-center overflow-hidden">
      <div className="mx-8 my-4 flex w-full max-w-screen-xl items-start justify-between">
        <Navbar
          bordName={data.name}
          countCards={allCardsNotConclued.length}
          totalArea={totalBoardArea}
        />
      </div>
      <ScrollContainer
        hideScrollbars={false}
        horizontal
        className="flex w-screen gap-8 px-11 pb-3"
      >
        {columns.map((column, index) => {
          const totalColumnArea = getTotalArea(column.cards)

          const areaPercentage = percentage(totalColumnArea, totalBoardArea)

          return (
            <Column
              key={column.id}
              {...column}
              columnIndex={index}
              percentage={areaPercentage}
              totalArea={totalColumnArea}
            />
          )
        })}
      </ScrollContainer>
    </main>
  )
}
