import { apiAthenas } from '@/services/api/apiAthenas'
import { Comment } from '@/types/Comment'

export async function addNewComment({
  demandId,
  userId,
  comment
}: {
  demandId: string
  userId: number
  comment: string
}) {
  const { data } = await apiAthenas.post<Comment>(
    `demands/${demandId}/comment`,
    {
      userId,
      comment
    }
  )
  return data
}
