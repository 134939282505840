import { apiAthenas } from '@/services/api/apiAthenas'

export async function updateComment({
  demandId,
  commentId,
  comment,
  userId
}: {
  demandId: string
  commentId: string
  comment: string
  userId: number
}) {
  await apiAthenas.put(`demands/${demandId}/comment/${commentId}`, {
    userId,
    comment
  })
}
