import { useGetDemand } from '@/hooks/useGetDemand'
import { FunctionComponent, useMemo } from 'react'

type Options<T> = Record<'weed' | 'planting_failure', FunctionComponent<T>>

export function useSwitchByTreatment<T>(options: Options<T>) {
  const { data } = useGetDemand()

  const CurrentComponent = useMemo(
    () => options[data?.treatment as keyof typeof options],
    [data?.treatment, options]
  )

  return CurrentComponent
}
