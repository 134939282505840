import { apiAthenas } from '@/services/api/apiAthenas'

type GetDownloadUrlProps = {
  demandId: string
  partId: string
  key: string
  value?: string
}

export async function getDownloadUrl({
  demandId,
  partId,
  key,
  value
}: GetDownloadUrlProps) {
  if (value) {
    return await apiAthenas.get<string>(
      `demands/${demandId}/parts/${partId}/file/${key}/download/${value}`
    )
  }

  return await apiAthenas.get<string>(
    `demands/${demandId}/parts/${partId}/file/${key}/download`
  )
}
