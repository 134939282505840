import { useMutation } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import JSZip from 'jszip'
import shp from 'shpjs'

import { FileType } from '@/types/fileTypes'

import { getUrlForUploadFiles } from '@/services/modules/athenas/demands/part-config/get-url-for-upload-files'
import { confirmPartResultUpload } from '@/services/modules/athenas/demands/part-config/confirm-part-result-upload'

import { handleToast } from '@/utils/handleToast'
import { FailureParts } from '@/types/Parts'
import { queryClient } from '@/providers'
import { makeGetFailurePartesKey } from '../queries/useGetFailureParts'
import { produce } from 'immer'
import { useCallback } from 'react'
import { partResultUpload } from '@/services/modules/googleStorage/part-result-upload'

type PartResultUpload = {
  partId: string
  fileType: FileType
  fileList: FileList
}

export function usePartResultUpload() {
  const { id } = useParams()

  const partResultUploadFromCache = useCallback(
    ({
      fileType,
      partId,
      listOfFileNames
    }: Partial<PartResultUpload> & { listOfFileNames: string[] }) =>
      queryClient.setQueryData<FailureParts>(
        makeGetFailurePartesKey(id!),
        produce((draft) => {
          if (!draft) return

          const partIndex = draft.parts.findIndex((part) => part.id === partId)

          if (fileType === 'planting_failure') {
            draft.parts[partIndex].plantingFailures.fileList = listOfFileNames

            return
          }
          draft.parts[partIndex].lineReconstruction.fileList = listOfFileNames
          return
        })
      ),
    [id]
  )

  const mutation = useMutation({
    mutationFn: async ({ fileType, fileList, partId }: PartResultUpload) => {
      const zip = new JSZip()

      const listOfFileNames = Array.from(fileList).map((file) => {
        return file.name
      })

      const url = await getUrlForUploadFiles({
        demandId: id!,
        fileType,
        partId
      })

      Array.from(fileList).map((file) => {
        zip.file(file.name, file)
      })

      const zipBlob = await zip.generateAsync({ type: 'uint8array' })

      await shp.parseZip(zipBlob)

      await partResultUpload({ demandId: id!, partId, fileType, url, zipBlob })

      await confirmPartResultUpload({
        demandId: id!,
        fileType,
        partId,
        listOfFileNames
      })

      partResultUploadFromCache({ fileType, partId, listOfFileNames })

      queryClient.invalidateQueries(makeGetFailurePartesKey(id!))
    },
    onError: () =>
      handleToast({
        type: 'error',
        message: 'Não foi possível enviar os arquivos. Tente novamente.'
      })
  })

  return mutation
}
