import { IndicatorColor } from '@/components/IndicatorColor'
import { AiData } from '@/types/AiData'

type TableValuesProps = AiData['value']

export function TableValues({
  imageQuality,
  infestation,
  lightness,
  text
}: TableValuesProps) {
  if (text) {
    return <td className="flex w-full justify-center">{text}</td>
  }

  if (lightness) {
    return (
      <td className="flex w-full items-center justify-center gap-2.5">
        <IndicatorColor color={lightness.color} />
        <span>{lightness.text}</span>
      </td>
    )
  }

  if (imageQuality) {
    return (
      <td className="flex w-full items-center justify-center gap-2.5">
        <IndicatorColor color={imageQuality.color} />
        <span>{imageQuality.text}</span>
      </td>
    )
  }

  if (infestation) {
    return (
      <td className="w-full text-center">
        <div className="flex  justify-center gap-4 text-end">
          <span className="w-16">{infestation.area} ha</span>
          <span className="w-16">{infestation.percentage}%</span>
        </div>
      </td>
    )
  }
}
