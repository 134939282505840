import { apiAthenas } from '@/services/api/apiAthenas'
import { FileType } from '@/types/fileTypes'

export async function downloadWeedResult({
  demandId,
  userId,
  fileType
}: {
  demandId: string
  userId: number
  fileType: FileType
}) {
  return await apiAthenas.get<string>(
    `demands/${demandId}/users/${userId}/${fileType}`
  )
}
