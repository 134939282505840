import { useMutation } from '@tanstack/react-query'
import { addNewComment } from '@/services/modules/athenas/demands/add-new-comment'
import { useParams } from 'react-router-dom'
import { useAuth } from '@/context/auth'
import { queryClient } from '@/providers'
import { makeGetDemandCommentsKey } from '../queries/useGetDemandComments'
import { useCallback } from 'react'
import { produce } from 'immer'
import { Comment } from '@/types/Comment'
import { handleToast } from '@/utils/handleToast'

export function useAddNewComment() {
  const { id } = useParams()
  const { user } = useAuth()

  const addCommentFromCache = useCallback(
    (comment: Comment) =>
      queryClient.setQueryData<Comment[]>(
        makeGetDemandCommentsKey(id!),
        produce((draft) => {
          if (!draft) return

          return [...draft, comment]
        })
      ),
    [id]
  )

  const mutation = useMutation({
    mutationFn: async ({ message }: { message: string }) => {
      const comment = await addNewComment({
        demandId: id!,
        userId: user.id,
        comment: message
      })

      addCommentFromCache(comment)
    },
    onError: () =>
      handleToast({
        message: 'Não foi possível adicionar o comentário. Tente novamente',
        type: 'error'
      })
  })

  return mutation
}
