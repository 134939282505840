import { apiAthenas } from '@/services/api/apiAthenas'

type AddSessionDurationProps = {
  demandId: string
  partId: string
  fileType: string
  time: string
  date: string
}
export async function addSessionDuration({
  demandId,
  partId,
  fileType,
  time,
  date
}: AddSessionDurationProps) {
  await apiAthenas.post(
    `demands/${demandId}/parts/${partId}/file/${fileType}/session`,
    { time, date }
  )
}
