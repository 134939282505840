import { useForm, Controller } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useState } from 'react'
import { z } from 'zod'

import { FileType } from '@/types/fileTypes'
import { Button } from '@/components'

import { useAddSessionDuration } from '@/hooks/demands/mutations/useAddSessionDuration'
import { twMerge } from '@/styles/tailwindMergeConfig'

const timeFormSchema = z.object({
  sessionDuration: z.string().regex(/^(?:[0-5][0-9]):[0-5][0-9]:[0-5][0-9]$/),
  date: z.string().refine((val) => !isNaN(Date.parse(val)))
})

type TimeFormSchema = z.infer<typeof timeFormSchema>

export function TimeForm({
  fileType,
  partId
}: {
  fileType: FileType
  partId: string
}) {
  const { mutate, isLoading } = useAddSessionDuration()
  const [time, setTime] = useState('')
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm<TimeFormSchema>({
    resolver: zodResolver(timeFormSchema)
  })

  const formatTime = (value: string) => {
    const cleanedValue = value.replace(/[^0-9]/g, '').slice(0, 6)
    return cleanedValue.replace(
      /(\d{2})(\d{0,2})(\d{0,2})/,
      (_, hh, mm, ss) => {
        let result = hh
        if (mm) result += `:${mm}`
        if (ss) result += `:${ss}`
        return result
      }
    )
  }

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    onChange: (value: string) => void
  ) => {
    const inputValue = e.target.value
    const formattedValue = formatTime(inputValue)
    setTime(formattedValue)
    onChange(formattedValue)
  }

  const onSubmit = handleSubmit((data) => {
    mutate({ date: data.date, time: data.sessionDuration, fileType, partId })
    reset({ date: '', sessionDuration: '' })
    setTime('')
  })

  return (
    <form onSubmit={onSubmit} className="mb-2">
      <h2
        className={twMerge(
          'text-titleSmall',
          'text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface'
        )}
      >
        Registrar tempo
      </h2>
      <section className="flex justify-between">
        <div className="mt-2 flex items-end gap-2">
          <Controller
            name="sessionDuration"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => (
              <label className="flex flex-col gap-2 font-mono text-labelMedium text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface">
                Duração da sessão
                <input
                  id="timeInput"
                  type="text"
                  value={value || time}
                  onChange={(e) => handleInputChange(e, onChange)}
                  maxLength={8}
                  placeholder="00:00:00"
                  className={twMerge(
                    'h-[32px] w-[205px] rounded border bg-transparent px-4 text-bodyMedium outline-none',
                    errors.sessionDuration
                      ? 'border-cromai-m3-ref-error-30'
                      : 'border-cromai-m3-sys-light-outline dark:border-cromai-m3-sys-dark-outline'
                  )}
                />
              </label>
            )}
          />
        </div>

        <div className="mt-2 flex items-end gap-2">
          <Controller
            name="date"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <label className="flex flex-col gap-2 font-mono text-labelMedium text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface">
                Data
                <input
                  data-testid="input-data"
                  type="date"
                  {...field}
                  className={twMerge(
                    'h-[32px] w-[205px] rounded border bg-transparent px-4 text-bodyMedium outline-none dark:[color-scheme:dark]',
                    errors.date
                      ? 'border-cromai-m3-ref-error-30'
                      : 'border-cromai-m3-sys-light-outline dark:border-cromai-m3-sys-dark-outline'
                  )}
                />
              </label>
            )}
          />
        </div>
      </section>
      <Button
        size="xs"
        variant="outlined"
        loading={isLoading}
        className="mt-2 w-full"
      >
        Registrar
      </Button>
    </form>
  )
}
