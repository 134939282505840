import { Story } from '@/components'
import { useBoardHistory } from '@/hooks/boards/queries/useBoardHistory'
import { Skeleton } from './Skeleton'

type StoriesProps = { boardId: string }

export function Stories({ boardId }: StoriesProps) {
  const {
    data: boardHistory,
    isLoading: isLoadingBoardHistory,
    isError
  } = useBoardHistory(boardId!)

  if (isLoadingBoardHistory || isError) {
    return <Skeleton isLoading={isLoadingBoardHistory} />
  }
  return (
    <>
      {boardHistory?.map((story) => (
        <Story
          key={story.dateTime}
          userId={story.userId}
          dateTime={story.dateTime}
          message={story.message}
          auxiliaryMessage={story.auxiliaryMessage}
        />
      ))}
    </>
  )
}
