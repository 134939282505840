import axios from 'axios'
import { FileType } from '@/types/fileTypes'

type UploadPerimeterProps = {
  url: string
  zipBlob: Uint8Array
  demandId: string
  fileType: FileType
}

export async function uploadPerimeter({
  url,
  demandId,
  fileType,
  zipBlob
}: UploadPerimeterProps) {
  await axios.put(url, zipBlob, {
    headers: {
      'x-goog-meta-demandId': demandId,
      'x-goog-meta-demandFileName': `${fileType}:${demandId}-${fileType}`,
      'Content-Type': 'application/zip'
    }
  })
}
