import * as DialogRadix from '@radix-ui/react-dialog'
import { FileType } from '@/types/fileTypes'
import { User } from '@/types/User'

import { useRemoveUserOnUniquePart } from '@/hooks/demands/mutations/useRemoveUserOnUniquePart'

import { Button, Dialog } from '@/components'

type RemoveUserDialogProps = {
  setIsOpen: (value: boolean) => void
  isOpen: boolean
  user: User | undefined
  partNumber: number
  partId: string
  fileType: FileType
}
export function RemoveUserDialog({
  isOpen,
  setIsOpen,
  partNumber,
  partId,
  user,
  fileType
}: RemoveUserDialogProps) {
  const { mutate, isLoading } = useRemoveUserOnUniquePart({
    onSuccess: () => setIsOpen(false)
  })

  return (
    <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="w-[432px] p-6">
        <DialogRadix.Title className="text-titleLarge text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface">{`Remover ${user?.name} da parte ${partNumber}`}</DialogRadix.Title>
        <DialogRadix.Description className="mt-2 text-bodyLarge text-cromai-m3-sys-light-on-surface-variant dark:text-cromai-m3-sys-dark-on-surface-variant">
          Os arquivos revisados, o progresso e tempo serão apagados.
        </DialogRadix.Description>
        <div className="mt-6 flex w-full justify-between">
          <DialogRadix.Close asChild>
            <Button variant="outlined">Cancelar</Button>
          </DialogRadix.Close>
          <Button
            loading={isLoading}
            className="w-24"
            onClick={() => mutate({ fileType, partId })}
          >
            Remover
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
