import shp from 'shpjs'
import JSzip from 'jszip'
import { useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'

import { FileType } from '@/types/fileTypes'
import { handleToast } from '@/utils/handleToast'

import { uploadPerimeter } from '@/services/modules/googleStorage/upload-perimeter'
import { partsDivisionTrigger } from '@/services/modules/athenas/demands/parts-division-trigger'
import { genereteUrlUploadPeriemter } from '@/services/modules/athenas/demands/generate-url-upload-perimeter'
import { queryClient } from '@/providers'
import { makeGetFailurePartesKey } from '../queries/useGetFailureParts'

interface MutationFnProps {
  fileList: FileList
  fileType: FileType
}

export function useUploadPeriemter() {
  const { id } = useParams()
  const mutation = useMutation({
    mutationFn: async ({ fileType, fileList }: MutationFnProps) => {
      const zip = new JSzip()

      const url = await genereteUrlUploadPeriemter({ demandId: id!, fileType })

      Array.from(fileList).map((file) => {
        zip.file(file.name, file)
      })

      const zipBlob = await zip.generateAsync({ type: 'uint8array' })

      await shp.parseZip(zipBlob)

      await uploadPerimeter({ url, demandId: id!, fileType, zipBlob })

      await partsDivisionTrigger({ demandId: id! })

      queryClient.resetQueries(makeGetFailurePartesKey(id!))
    },
    onSuccess: () =>
      handleToast({
        type: 'success',
        message: 'As partes da demanda foram criadas.'
      }),
    onError: () =>
      handleToast({
        type: 'error',
        message: 'Não possível criar as partes. Tente novamente.'
      })
  })
  return mutation
}
