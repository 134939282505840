import { getAtlasData } from '@/services/modules/athenas/demands/get-atlas-data'
import { handleToast } from '@/utils/handleToast'
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

export function useGetAtlasData() {
  const { id } = useParams()

  const query = useQuery({
    queryKey: ['ATLAS_DATA', id],
    queryFn: () => getAtlasData(id!)
  })

  useEffect(() => {
    if (query.isError) {
      handleToast({
        message:
          'Ocorreu um erro ao carregar os dados do Atlas. Tente novamente mais tarde.',
        type: 'error',
        autoClose: false
      })
    }
  }, [query.isError, query.error])

  return query
}
