import { Menu, ProgressBar } from '@/components'

import { MdMoreVert, MdOutlineDelete } from 'react-icons/md'
import { twMerge } from 'tailwind-merge'
import { DeleteAllPartsDialog } from './DeleteAllPartsDialog'
import { useState } from 'react'

type AllPartsInfoProps = {
  lineReconstructionProgress: number
  plantingFailuresProgress: number
}

export function AllPartsInfo({
  lineReconstructionProgress,
  plantingFailuresProgress
}: AllPartsInfoProps) {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <div
        className={twMerge(
          'flex min-w-[655px] items-center gap-6 rounded-lg px-4 py-2.5 font-mono text-bodyMedium',
          'bg-cromai-m3-sys-light-surface dark:bg-cromai-m3-sys-dark-surface'
        )}
      >
        <div className="flex flex-col items-start gap-1">
          <span className="text-bodySmall">Reconstituição de linhas</span>
          <div className="flex items-center gap-1 text-labelLarge">
            {lineReconstructionProgress}%
            <ProgressBar progress={lineReconstructionProgress} />
          </div>
        </div>

        <div className="mr-auto flex flex-col items-start gap-1">
          <span className="text-bodySmall">Falhas de plantio</span>
          <div className="flex items-center gap-1 text-labelLarge">
            {plantingFailuresProgress}%
            <ProgressBar progress={plantingFailuresProgress} />
          </div>
        </div>

        <Menu
          align="end"
          side="bottom"
          className=" w-52"
          options={[
            {
              label: 'Remover partes',
              icon: <MdOutlineDelete className="mr-3" size={24} />,
              onClick: () => setIsOpen(true)
            }
          ]}
          trigger={
            <div
              aria-label="Mais"
              className={twMerge(
                'flex size-8 shrink-0 items-center justify-center rounded-full',
                'transition-colors hover:bg-[#bdc2b9] dark:hover:bg-cromai-m3-sys-dark-surface-variant'
              )}
            >
              <MdMoreVert size={24} />
            </div>
          }
        />
      </div>
      <DeleteAllPartsDialog isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  )
}
