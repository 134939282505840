import { apiAthenas } from '@/services/api/apiAthenas'
import { Comment } from '@/types/Comment'

export async function getDemandComments(demandId: string) {
  const { data } = await apiAthenas.get<Comment[]>(
    `demands/${demandId}/comment`
  )

  return data
}
