import { twMerge } from '@/styles/tailwindMergeConfig'
import { Tooltip } from '@/components'

type ChipProps = {
  label: string
  tooltipText?: string
}

export function Chip({ label, tooltipText }: ChipProps) {
  return (
    <Tooltip
      trigger={
        <span
          aria-label={label}
          className={twMerge(
            'h-6 min-w-11 max-w-36 flex-shrink-0 items-center whitespace-nowrap rounded-full border px-4',
            'truncate font-mono text-[10px] font-semibold leading-[1.40rem] tracking-widest',
            'text-cromai-m3-sys-light-on-secondary-container dark:text-cromai-m3-sys-dark-on-secondary-container',
            'border-cromai-m3-sys-light-outline dark:border-cromai-m3-sys-dark-outline',
            'bg-cromai-m3-sys-light-secondary-container dark:bg-cromai-m3-sys-dark-secondary-container'
          )}
        >
          {label}
        </span>
      }
      text={tooltipText}
    />
  )
}
