import { useState } from 'react'
import { Link } from 'react-router-dom'

import * as Tooltip from '@radix-ui/react-tooltip'
import { Button, DeadlineTag, StatusTag } from '@/components'

import {
  MdImage,
  MdLink,
  MdOutlineAreaChart,
  MdVisibility
} from 'react-icons/md'

import { twMerge } from '@/styles/tailwindMergeConfig'
import { useCopy } from '@/hooks/useCopy'
import { useGetDemand } from '@/hooks/useGetDemand'

import { Actions } from './Actions'
import { RecoverDialog } from './RecoverDialog'
import { useDeliveryDiaolog } from './hooks/useDeliveryDiaolog'

export function Header() {
  const [isOpenRecoveryDiaolg, setIsOpenRecoveryDiaolg] = useState(false)

  const { CurrentDeliveryDialog, openCurrentDeliveryDialog } =
    useDeliveryDiaolog()

  const { copy } = useCopy()
  const { data } = useGetDemand()

  const roUrl = data!.roUrl

  return (
    <header className="flex w-full max-w-screen-xl items-start justify-between gap-8 pt-3">
      <div className="flex w-0 flex-1">
        <div className="truncate">
          <div className="flex text-cromai-m3-sys-light-on-background dark:text-cromai-m3-sys-dark-on-background">
            <h1
              role="heading"
              className="w-full items-center truncate text-labelXLarge text-cromai-m3-sys-light-on-background dark:text-cromai-m3-sys-dark-on-background lg:flex-1"
            >
              {data!.demandName}
            </h1>
            <div className="ml-6 flex items-center gap-2 text-labelLarge">
              {data?.isMosaic ? (
                <MdImage size={24} title="Mosaico" />
              ) : (
                <MdOutlineAreaChart size={24} />
              )}
              <span className="whitespace-nowrap">{data!.area} ha</span>
            </div>
          </div>
          <div className="mt-1.5 flex items-center gap-2">
            {data?.isQA && (
              <MdVisibility
                title="QA"
                className="text-cromai-m3-ref-tertiary-30 dark:text-cromai-m3-ref-tertiary-80"
                size={24}
              />
            )}
            <span className=" flex items-center gap-2 text-labelSmall text-cromai-m3-sys-light-outline dark:text-cromai-m3-sys-dark-outline">
              {data!.id}
              <Tooltip.Provider delayDuration={0} skipDelayDuration={0}>
                <Tooltip.Root>
                  <Tooltip.Trigger asChild type="button">
                    <button
                      aria-label="Copiar ID"
                      className="flex w-auto items-center gap-[6px] hover:opacity-80"
                      onClick={() => copy(data!.id)}
                    >
                      <MdLink
                        size={12}
                        className="text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface"
                      />
                    </button>
                  </Tooltip.Trigger>
                  <Tooltip.Portal>
                    <Tooltip.Content
                      className="ml-2"
                      align="center"
                      side="right"
                    >
                      <div className="mr-3 flex items-center gap-2 truncate rounded-sm bg-cromai-m3-sys-light-surface-variant px-3 text-labelSmall text-cromai-m3-sys-light-on-surface-variant dark:bg-cromai-m3-sys-dark-surface-variant dark:text-cromai-m3-sys-dark-on-surface-variant">
                        <>Copiar ID</>
                      </div>
                    </Tooltip.Content>
                  </Tooltip.Portal>
                </Tooltip.Root>
              </Tooltip.Provider>
            </span>
          </div>
          <div className="mt-2 flex gap-4">
            <span
              className={twMerge(
                'border-cromai-m3-sys-light-outline text-cromai-m3-sys-light-on-background',
                'dark:border-cromai-m3-sys-dark-outline dark:text-cromai-m3-sys-dark-on-background',
                'flex h-6 w-fit items-center whitespace-nowrap rounded border px-2 py-1 font-mono text-labelMedium'
              )}
            >
              {data!.companyName}
            </span>
            <StatusTag
              variant="border"
              status={data!.status}
              align="start"
              side="bottom"
            />
            <DeadlineTag date={data!.term.date} type={data!.term.type} />
          </div>
        </div>
      </div>

      <div className="flex gap-4">
        <Actions />
        <div className={twMerge('hidden gap-4 lg:flex')}>
          <Link
            to={roUrl}
            target="_blank"
            className={`${!roUrl && 'pointer-events-none'}`}
          >
            <Button disabled={!roUrl} variant="outlined" size="sm">
              Acessar revisão
            </Button>
          </Link>
          {data?.status.value === 'concluded' ? (
            <Button
              size="sm"
              variant="outlined"
              onClick={() => setIsOpenRecoveryDiaolg(true)}
            >
              Reaver
            </Button>
          ) : (
            <Button size="sm" onClick={openCurrentDeliveryDialog}>
              Entregar
            </Button>
          )}
          <CurrentDeliveryDialog />
          <RecoverDialog
            isOpen={isOpenRecoveryDiaolg}
            setIsOpen={setIsOpenRecoveryDiaolg}
            demandId={data!.id}
            demandName={data!.demandName}
          />
        </div>
      </div>
    </header>
  )
}
