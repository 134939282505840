import { ReactNode } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

export function CustomLink({
  href,
  name,
  icon
}: {
  href: string
  name: string
  icon: ReactNode
}) {
  const { pathname } = useLocation()

  const isActive = pathname === href

  return (
    <Link
      key={name}
      to={href}
      className={twMerge(
        'group flex flex-col items-center',
        'text-cromai-m3-sys-light-on-surface-variant dark:text-cromai-m3-sys-dark-on-surface-variant'
      )}
    >
      <div
        data-testid="link-style-icon"
        className={twMerge(
          'flex w-full justify-center rounded-full py-1 transition-all',
          isActive
            ? 'bg-cromai-m3-sys-light-tertiary dark:bg-cromai-m3-sys-dark-tertiary'
            : 'group-hover:bg-cromai-m3-sys-light-tertiary group-hover:dark:bg-cromai-m3-sys-dark-tertiary'
        )}
      >
        <div
          className={twMerge(
            'transition-all',
            isActive
              ? 'text-cromai-m3-sys-light-on-tertiary dark:text-cromai-m3-sys-dark-on-tertiary'
              : 'group-hover:text-cromai-m3-sys-light-on-tertiary group-hover:dark:text-cromai-m3-sys-dark-on-tertiary'
          )}
        >
          {icon}
        </div>
      </div>
      <span className="my-1 font-mono text-labelSmall">{name}</span>
    </Link>
  )
}
