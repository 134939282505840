import { Part } from '@/types/Parts'

import { MdFileDownload } from 'react-icons/md'
import { usePartFileDownload } from '@/hooks/demands/mutations/usePartFileDownload'

import { Menu } from '@/components'

type DownloadMenuProps = {
  partId: string
  partNumber: number
  isResults: boolean
  downloadFiles: Part['downloadFiles']
}

export function DownloadMenu({
  partId,
  partNumber,
  isResults,
  downloadFiles
}: DownloadMenuProps) {
  const { mutate } = usePartFileDownload()

  const { perimeter } = downloadFiles

  function handleDownload({ key, value }: { key: string; value?: string }) {
    value ? mutate({ partId, key, value }) : mutate({ partId, key })
  }

  return (
    <Menu
      align="end"
      side="bottom"
      header={`Parte ${partNumber}`}
      trigger={
        <div
          aria-label="download-menu"
          className="rounded p-2 outline-none transition-colors hover:bg-[#bdc2b9] dark:hover:bg-cromai-m3-sys-dark-surface-variant"
        >
          <MdFileDownload size={12} />
        </div>
      }
      options={[
        {
          label: 'Perímetro',
          disabled: !perimeter,
          onClick: () => handleDownload(perimeter!)
        },
        {
          label: 'Resultados revisados',
          disabled: !isResults,
          onClick: () => handleDownload({ key: 'results' })
        }
      ]}
    />
  )
}
