import { FilterDisplay, Filters } from '@/components'
import { MdFunctions, MdNumbers } from 'react-icons/md'

export function Navbar({
  bordName,
  countCards,
  totalArea
}: {
  bordName: string
  countCards: number
  totalArea: number
}) {
  return (
    <div className="mx-4 flex w-full items-center gap-8">
      <h1 className="whitespace-nowrap text-start text-headlineSmall dark:text-cromai-m3-sys-dark-on-surface">
        {bordName}
      </h1>
      <div className="flex flex-1 gap-8 whitespace-nowrap text-labelLarge dark:text-cromai-m3-sys-dark-on-background">
        <span className="flex items-center gap-1 ">
          <MdNumbers size={16} />
          {countCards}
        </span>
        <span className="flex items-center gap-1">
          <MdFunctions size={16} />
          {totalArea} ha
        </span>
        <Filters />
        <FilterDisplay />
      </div>
    </div>
  )
}
