export function Skeleton({ isLoading }: { isLoading?: boolean }) {
  return (
    <div
      role="progressbar"
      className={`flex size-full flex-col gap-4 ${isLoading && 'animate-pulse'}`}
    >
      <div className="flex max-h-[calc(100%-64px)] flex-col gap-5 overflow-hidden ">
        {Array.from({ length: 9 }).map((_, i) => (
          <div key={`item-${i}`} className="flex w-full gap-2 pl-5">
            <div className="flex size-6 rounded-full bg-cromai-m3-sys-light-background dark:bg-cromai-m3-sys-dark-surface2" />
            <div className="flex h-10 w-[90%] flex-col gap-2">
              <div className="mt-1 h-3 w-full rounded-sm bg-cromai-m3-sys-light-background dark:bg-cromai-m3-sys-dark-surface2" />
              <div className="h-2 w-3/4 rounded-sm bg-cromai-m3-sys-light-background dark:bg-cromai-m3-sys-dark-surface2" />
            </div>
          </div>
        ))}
      </div>
      <div className="mt-auto flex h-12 max-h-80 w-full rounded-lg bg-cromai-m3-sys-light-background dark:bg-cromai-m3-sys-dark-surface2" />
    </div>
  )
}
