import { PropsWithChildren } from 'react'
import { SideBar } from '@/components/SideBar'

export function DashboardLayout({ children }: PropsWithChildren) {
  return (
    <div className="relative flex bg-cromai-m3-sys-light-background dark:bg-cromai-m3-sys-dark-background">
      <SideBar />
      {children}
    </div>
  )
}
