import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

import { twMerge } from '@/styles/tailwindMergeConfig'
import { FileType } from '@/types/fileTypes'
import { Button } from '@/components'

import { useChangeProgressOnUniquePart } from '@/hooks/demands/mutations/useChangeProgressOnUniquePart'

type ProgressFormProps = {
  className?: string
  progress: number
  fileType: FileType
  partId: string
}

const progressSchema = z.object({
  progress: z.number().min(0).max(100)
})

type ProgressSchema = z.infer<typeof progressSchema>

export function ProgressForm({
  progress,
  fileType,
  partId
}: ProgressFormProps) {
  const { mutate, isLoading } = useChangeProgressOnUniquePart()

  const {
    handleSubmit,
    control,
    formState: { errors }
  } = useForm<ProgressSchema>({
    resolver: zodResolver(progressSchema)
  })

  const onSubmit = handleSubmit((data) =>
    mutate({ fileType, partId, progress: data.progress })
  )

  return (
    <form
      onSubmit={onSubmit}
      className="my-6 text-titleSmall text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface"
    >
      <h1 className="mb-2">Atualizar progresso</h1>
      <div className="flex w-full items-center justify-between">
        <Controller
          name="progress"
          control={control}
          defaultValue={undefined}
          render={({ field: { onChange } }) => (
            <label
              data-testid="input-box"
              className={twMerge(
                'relative h-[32px] w-[205px] rounded border',
                'text-bodyMedium',
                errors.progress
                  ? 'border-cromai-m3-ref-error-30'
                  : 'border-cromai-m3-sys-light-outline dark:border-cromai-m3-sys-dark-outline'
              )}
            >
              <input
                type="number"
                onChange={(e) => onChange(Number(e.target.value))}
                min={0}
                max={100}
                placeholder={`${progress}`}
                className={twMerge(
                  'no-arrow-buttons h-full w-full bg-transparent px-4 font-mono text-bodyMedium outline-none'
                )}
              />
              <span className="absolute right-4 top-1.5">%</span>
            </label>
          )}
        />
        <Button
          className="w-[205px]"
          loading={isLoading}
          variant="outlined"
          size="xs"
        >
          Atualizar
        </Button>
      </div>
    </form>
  )
}
