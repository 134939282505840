import { useGetUsers } from './demands/queries/useGetUsers'

export function useUserInfos() {
  const { data } = useGetUsers()

  function findUserInfo(userId: number | null) {
    if (!userId) return

    return data?.find((user) => user.id === userId)
  }

  return { findUserInfo }
}
