import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'
import { queryClient } from '@/providers'
import { useParams } from 'react-router-dom'
import { produce } from 'immer'

import { makeGetFailurePartesKey } from '../queries/useGetFailureParts'

import { FileType } from '@/types/fileTypes'
import { FailureParts } from '@/types/Parts'

import { addUserOnUniquePart } from '@/services/modules/athenas/demands/part-config/add-user-on-unique-part'
import { handleToast } from '@/utils/handleToast'
import { makeGetDemandKey } from '@/hooks/useGetDemand'

type AddUserOnUniquePart = {
  partId: string
  fileType: FileType
  userId: number
}

export function useAddUserOnUniquePart() {
  const { id } = useParams()

  const addUserOnUniquePartFromCache = useCallback(
    ({ fileType, partId, userId }: AddUserOnUniquePart) =>
      queryClient.setQueryData<FailureParts>(
        makeGetFailurePartesKey(id!),
        produce((draft) => {
          if (!draft) return

          const partIndex = draft.parts.findIndex((part) => part.id === partId)

          if (fileType === 'planting_failure') {
            draft.parts[partIndex].plantingFailures.userId = userId
            return
          }
          draft.parts[partIndex].lineReconstruction.userId = userId
          return
        })
      ),
    [id]
  )

  const mutation = useMutation({
    mutationFn: async ({ fileType, partId, userId }: AddUserOnUniquePart) => {
      await addUserOnUniquePart({ demandId: id!, fileType, partId, userId })

      addUserOnUniquePartFromCache({ fileType, partId, userId })

      queryClient.invalidateQueries(makeGetDemandKey(id!))
    },
    onError: () =>
      handleToast({
        type: 'error',
        message: 'Não foi possível adicionar o responsável. Tente novamente.'
      })
  })

  return mutation
}
