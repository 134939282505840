import { useTheme } from '@/context/theme'

export function AthenasLogo({ size = 40 }: { size?: number }) {
  const { theme } = useTheme()

  const fillColor = theme === 'light' ? '#00658B' : '#79D0FF'

  return (
    <figure title="Cromai Athenas">
      <svg
        width={size}
        height={size}
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20 9.99992C20.9205 9.99992 21.6667 9.25373 21.6667 8.33325C21.6667 7.41278 20.9205 6.66659 20 6.66659C19.0795 6.66659 18.3333 7.41278 18.3333 8.33325C18.3333 9.25373 19.0795 9.99992 20 9.99992ZM20 13.3333C22.7614 13.3333 25 11.0947 25 8.33325C25 5.57183 22.7614 3.33325 20 3.33325C17.2386 3.33325 15 5.57183 15 8.33325C15 11.0947 17.2386 13.3333 20 13.3333Z"
          fill={fillColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.7504 13.4171C10.1188 15.0487 9.00767 17.1275 8.55751 19.3906C8.10735 21.6537 8.33839 23.9995 9.22141 26.1313C10.1044 28.2631 11.5998 30.0852 13.5184 31.3671C14.9713 32.338 16.6176 32.966 18.3333 33.2137V26.3821C16.3913 25.6957 15 23.8437 15 21.6666C15 18.9052 17.2386 16.6666 20 16.6666C22.7614 16.6666 25 18.9052 25 21.6666C25 23.8437 23.6087 25.6957 21.6667 26.3821V33.2137C23.3824 32.966 25.0287 32.338 26.4817 31.3671C28.4002 30.0852 29.8956 28.2631 30.7786 26.1313C31.6616 23.9995 31.8927 21.6537 31.4425 19.3906C30.9923 17.1275 29.8812 15.0487 28.2496 13.4171L30.6066 11.0601C32.7044 13.1579 34.133 15.8306 34.7118 18.7403C35.2906 21.65 34.9935 24.666 33.8582 27.4069C32.7229 30.1478 30.8003 32.4905 28.3336 34.1387C25.8668 35.7869 22.9667 36.6667 20 36.6667C17.0333 36.6667 14.1332 35.7869 11.6664 34.1387C9.19971 32.4905 7.27713 30.1478 6.14181 27.4069C5.0065 24.666 4.70945 21.65 5.28823 18.7403C5.86701 15.8306 7.29562 13.1578 9.39341 11.0601L11.7504 13.4171ZM20 23.3333C20.9205 23.3333 21.6667 22.5871 21.6667 21.6666C21.6667 20.7462 20.9205 20 20 20C19.0795 20 18.3333 20.7462 18.3333 21.6666C18.3333 22.5871 19.0795 23.3333 20 23.3333Z"
          fill={fillColor}
        />
      </svg>
    </figure>
  )
}
