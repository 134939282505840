import { queryClient } from '@/providers'
import { handleToast } from '@/utils/handleToast'
import { useMutation } from '@tanstack/react-query'
import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { produce } from 'immer'

import { makeGetFailurePartesKey } from '../queries/useGetFailureParts'
import { FailureParts } from '@/types/Parts'
import { FileType } from '@/types/fileTypes'

import { partResultDelete } from '@/services/modules/athenas/demands/part-config/part-result-delete'

type PartResultDelete = { partId: string; fileType: FileType }

export function usePartResultDelete() {
  const { id } = useParams()

  const partResultDeleteFromCache = useCallback(
    ({ fileType, partId }: PartResultDelete) =>
      queryClient.setQueryData<FailureParts>(
        makeGetFailurePartesKey(id!),
        produce((draft) => {
          if (!draft) return

          const partIndex = draft.parts.findIndex((part) => part.id === partId)

          if (fileType === 'planting_failure') {
            draft.parts[partIndex].plantingFailures.fileList = []

            return
          }
          draft.parts[partIndex].lineReconstruction.fileList = []
          return
        })
      ),
    [id]
  )

  const mutation = useMutation({
    mutationFn: async ({ fileType, partId }: PartResultDelete) => {
      partResultDeleteFromCache({ fileType, partId })
      await partResultDelete({ demandId: id!, fileType, partId })
    },

    onError: () => {
      queryClient.invalidateQueries(makeGetFailurePartesKey(id!))

      handleToast({
        type: 'error',
        message: 'Não foi possível deletar os arquivos. Tente novamente.'
      })
    }
  })

  return mutation
}
