import { getAiData } from '@/services/modules/athenas/demands/get-ai-data'
import { handleToast } from '@/utils/handleToast'
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

export function useGetAiData() {
  const { id } = useParams()

  const query = useQuery({
    queryKey: ['AI_DATA', id],
    queryFn: () => getAiData(id!)
  })

  useEffect(() => {
    if (query.isError) {
      handleToast({
        message:
          'Ocorreu um erro ao carregar os dados de IA. Tente novamente mais tarde.',
        type: 'error',
        autoClose: false
      })
    }
  }, [query.isError, query.error])

  return query
}
