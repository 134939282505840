import * as Popover from '@radix-ui/react-popover'

import { DialogUploadWeed } from './DialogUploadWeed'
import { DialogUploadFailure } from './DialogUploadFailure'
import { DialogRemoveReviewer } from './DialogRemoveReviewer'

import { useDownloadAllWeedResults } from '@/hooks/demands/mutations/useDownloadAllWeedResults'
import { useUpdateIsQAOnResponsible } from '@/hooks/demands/mutations/useUpdateIsQAOnResponsible'
import { useGetDemand } from '@/hooks/useGetDemand'
import { Reviewer } from '@/types/demands'

import { twMerge } from 'tailwind-merge'

import { MdMoreVert as TreeDotsIcon } from 'react-icons/md'

export function Options({ reviewer }: { reviewer: Reviewer }) {
  const { data } = useGetDemand()
  const { mutate: updateIsQAOnResponsible } = useUpdateIsQAOnResponsible()
  const { mutate: downloadAllWeedResults } = useDownloadAllWeedResults()

  return (
    <Popover.Root>
      <Popover.Trigger
        aria-label="Menu"
        className="ml-7 flex size-6 items-center justify-center rounded-full transition-colors hover:bg-cromai-m3-sys-light-surface-variant dark:hover:bg-cromai-m3-sys-dark-surface-variant"
      >
        <TreeDotsIcon
          size={16}
          className="text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface"
        />
      </Popover.Trigger>
      <Popover.Content
        align="end"
        alignOffset={-16}
        className="flex flex-col rounded bg-cromai-m3-sys-light-surface2 py-1.5 shadow-lightElevation2 dark:bg-cromai-m3-sys-dark-surface2 dark:shadow-darkElevation2"
      >
        {data?.treatment === 'weed' ? (
          <DialogUploadWeed demand={data!} reviewer={reviewer} />
        ) : (
          <DialogUploadFailure demand={data!} reviewer={reviewer} />
        )}
        {data?.treatment === 'weed' && (
          <button
            onClick={() => downloadAllWeedResults({ userId: reviewer.userId })}
            className={twMerge(
              'flex items-center gap-3 px-3 py-1.5',
              'hover:bg-cromai-m3-sys-light-surface2-hover dark:hover:bg-cromai-m3-sys-dark-surface2-hover',
              'text-cromai-m3-sys-light-inverse-surface dark:text-cromai-m3-sys-dark-inverse-surface'
            )}
          >
            Baixar resultados
          </button>
        )}
        <button
          onClick={() =>
            updateIsQAOnResponsible({
              demandId: data!.id,
              isQA: !reviewer!.isQA,
              reviewerId: reviewer.userId
            })
          }
          className={twMerge(
            'flex items-center gap-3 px-3 py-1.5',
            'hover:bg-cromai-m3-sys-light-surface2-hover dark:hover:bg-cromai-m3-sys-dark-surface2-hover',
            'text-cromai-m3-sys-light-inverse-surface dark:text-cromai-m3-sys-dark-inverse-surface'
          )}
        >
          {reviewer.isQA ? 'Remover' : 'Adicionar'} QA
        </button>
        <DialogRemoveReviewer
          reviewer={{
            userId: reviewer.userId,
            userName: reviewer.userName,
            revisionTime: reviewer.revisionTime
          }}
          demandId={data!.id}
        />
      </Popover.Content>
    </Popover.Root>
  )
}
