import { getBoard } from '@/services/modules/athenas/boards/get-board'
import { handleToast } from '@/utils/handleToast'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { useMakeGetBoardKey } from './useMakeGetBoardKey'

export function useGetBoard() {
  const { id } = useParams()
  const { queryKey, params } = useMakeGetBoardKey(id!)

  const query = useQuery({
    queryKey: queryKey,
    queryFn: () => getBoard(id!, params),
    onError: () =>
      handleToast({
        message: 'Ocorreu um erro ao carregar as demandas. Atualize a página.',
        type: 'error',
        autoClose: false
      })
  })

  return query
}
