import { apiAthenas } from '@/services/api/apiAthenas'
import { FileType } from '@/types/fileTypes'

export type AddUserOnUniquePartProps = {
  demandId: string
  partId: string
  fileType: FileType
  userId: number
}

export async function addUserOnUniquePart({
  demandId,
  partId,
  fileType,
  userId
}: AddUserOnUniquePartProps) {
  await apiAthenas.post(
    `demands/${demandId}/parts/${partId}/file/${fileType}/user/${userId}`
  )
}
