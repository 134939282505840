import { twMerge } from 'tailwind-merge'
import { PartFile } from '@/types/Parts'
import { FileType } from '@/types/fileTypes'

import { useUserInfos } from '@/hooks/useUserInfos'

import { AddUserMenu } from './AddUserMenu'
import { UnitPartConfig } from './UnitPartConfig'
import { Avatar, ProgressBar } from '@/components'

type UnitPartProps = {
  area: number
  partId: string
  partNumber: number
  fileType: FileType
} & PartFile

export function UnitPart({
  area,
  partId,
  partNumber,
  fileType,
  userId,
  fileList,
  progress,
  totalRevisionTime,
  timeRecords,
  downloadReviewedFile
}: UnitPartProps) {
  const { findUserInfo } = useUserInfos()

  const user = findUserInfo(userId)

  const isUser = !!user

  return (
    <div className="flex items-center gap-2">
      {isUser ? (
        <Avatar size="small" name={user.name} />
      ) : (
        <AddUserMenu isTriggerIcon partId={partId} fileType={fileType} />
      )}
      <span
        className={twMerge(
          !isUser && 'opacity-50',
          'font-mono text-labelMedium'
        )}
      >
        {totalRevisionTime ? totalRevisionTime : '00:00:00'}
      </span>
      <div
        className={twMerge(!isUser && 'opacity-50', 'flex items-center gap-1')}
      >
        <span className="w-8 text-end text-labelMedium">{progress}%</span>
        <ProgressBar progress={progress} />
      </div>
      <UnitPartConfig
        downloadReviewedFile={downloadReviewedFile}
        user={user}
        area={area}
        partId={partId}
        fileList={fileList}
        fileType={fileType}
        partNumber={partNumber}
        progress={progress}
        timeRecords={timeRecords}
        totalRevisionTime={totalRevisionTime}
      />
    </div>
  )
}
