import * as RadioGroup from '@radix-ui/react-radio-group'
import { twMerge } from '@/styles/tailwindMergeConfig'

const mappingLabel = {
  parts: 'Partes',
  links: 'Links',
  atlasData: 'Dados do Atlas',
  aiData: 'Dados de IA',
  history: 'Histórico',
  comments: 'Comentários',
  responsibles: 'Responsáveis',
  'revision-time': 'Seu tempo de revisão'
}

type TabOptionsProps = {
  ariaLabel: string
  defaultValue: string
  options: string[]
  selectedOption: string
  setSelectedOption: (value: string) => void
}

export function TabOptions({
  ariaLabel,
  defaultValue,
  setSelectedOption,
  selectedOption,
  options
}: TabOptionsProps) {
  return (
    <RadioGroup.Root
      defaultValue={defaultValue}
      aria-label={ariaLabel}
      className="flex w-full gap-2"
      onValueChange={(value: string) => setSelectedOption(value)}
      value={selectedOption}
    >
      {options.map((option) => {
        return (
          <RadioGroup.Item
            key={option}
            className={twMerge(
              'min-w-20 transition-all',
              'data-[state="checked"]:activeToggle',
              'data-[state="checked"]:pointer-events-none',

              'data-[state="unchecked"]:disabledToggle',
              'data-[state="unchecked"]:hover:activeToggle',
              'data-[state="unchecked"]:hover:opacity-60'
            )}
            value={option}
            aria-label={option}
          >
            <span>{mappingLabel[option]}</span>
          </RadioGroup.Item>
        )
      })}
    </RadioGroup.Root>
  )
}
