import { getFailureParts } from '@/services/modules/athenas/demands/get-failure-parts'
import { handleToast } from '@/utils/handleToast'
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

const FAILURE_PARTS_KEY = 'FAILURE_PARTS'

export function makeGetFailurePartesKey(id: string) {
  return [FAILURE_PARTS_KEY, id]
}

export function useGetFailureParts() {
  const { id } = useParams()
  const query = useQuery({
    queryKey: makeGetFailurePartesKey(id!),
    queryFn: () => getFailureParts(id!)
  })

  useEffect(() => {
    if (query.isError) {
      handleToast({
        message:
          'Ocorreu um erro ao carregar as partes da demanda. Tente novamente mais tarde.',
        type: 'error',
        autoClose: false
      })
    }
  }, [query.isError, query.error])

  return query
}
