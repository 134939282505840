import { useMutation } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { queryClient } from '@/providers'

import { handleToast } from '@/utils/handleToast'
import { makeGetDemandKey } from '@/hooks/useGetDemand'

import { growLineDelivery } from '@/services/modules/athenas/demands/part-config/grow-line-delivery'

export function useGrowLineDelivery({ onSuccess }: { onSuccess: () => void }) {
  const { id } = useParams()

  const mutation = useMutation({
    mutationFn: async () => {
      await growLineDelivery({ demandId: id! })

      queryClient.invalidateQueries(makeGetDemandKey(id!))
    },
    onSuccess: () => {
      onSuccess()
      handleToast({
        type: 'success',
        message: 'A entrega da demanda está sendo processada.'
      })
    },
    onError: () =>
      handleToast({
        type: 'error',
        message: 'Ocorreu um erro ao entregar a demanda. Tente novamente.'
      })
  })

  return mutation
}
