import * as ScrollArea from '@radix-ui/react-scroll-area'
import * as Menu from '@radix-ui/react-popover'

import { Button } from '@/components'
import { useMemo, useState } from 'react'
import { MdOutlinePersonAdd as PersonAddIcon } from 'react-icons/md'
import { twMerge } from '@/styles/tailwindMergeConfig'
import { useGetUsers } from '@/hooks/demands/queries/useGetUsers'
import { User } from './User'
import { FileType } from '@/types/fileTypes'

interface AddUserMenuProps {
  fileType: FileType
  partId: string
  isTriggerIcon?: boolean

  side?: 'top' | 'right' | 'bottom' | 'left' | undefined
  align?: 'center' | 'start' | 'end' | undefined
  sideOffset?: number
  alignOffset?: number
}

export function AddUserMenu({
  isTriggerIcon,
  fileType,
  partId,
  align,
  side,
  alignOffset,
  sideOffset
}: AddUserMenuProps) {
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false)
  const [filter, setFilter] = useState<string>('')

  const { data: users } = useGetUsers()

  const filteredReviewers = useMemo(
    () =>
      users?.filter((user) =>
        user.name.toLowerCase().includes(filter.toLowerCase())
      ),
    [filter, users]
  )

  return (
    <Menu.Root onOpenChange={(open) => setIsOpenMenu(open)}>
      <Menu.Trigger asChild className="w-full">
        {isTriggerIcon ? (
          <button
            title="Adicionar"
            className="m-1 transition-all hover:opacity-70"
          >
            <PersonAddIcon size={16} />
          </button>
        ) : (
          <Button
            className={`mb-4 w-full ${
              isOpenMenu &&
              'bg-cromai-m3-sys-light-inverse-on-surface dark:bg-cromai-m3-sys-dark-inverse-on-surface'
            }`}
            variant="outlined"
          >
            <PersonAddIcon size={18} />
            Adicionar responsáveis
          </Button>
        )}
      </Menu.Trigger>
      <Menu.Content
        side={side}
        align={align}
        sideOffset={sideOffset}
        alignOffset={alignOffset}
        className="z-50"
      >
        <div
          className={twMerge(
            'w-[321px] rounded bg-[#EFF6ED] dark:bg-[#1E261E]'
          )}
        >
          <div className="mb-2 w-full px-4 pt-4">
            <input
              type="text"
              title="Pesquisar"
              placeholder="Pesquisar"
              onChange={(e) => setFilter(e.target.value)}
              className="w-full rounded border border-cromai-m3-sys-light-outline bg-transparent px-4 py-2 text-cromai-m3-sys-light-outline dark:border-cromai-m3-sys-dark-outline dark:text-cromai-m3-sys-dark-outline"
            />
          </div>
          <ScrollArea.Root className="h-[calc(100vh-30rem)] overflow-hidden">
            <ScrollArea.Viewport className="size-full border-inherit px-4">
              {filteredReviewers?.map((user) => {
                return (
                  <User
                    fileType={fileType}
                    partId={partId}
                    key={user.id}
                    user={{
                      id: user.id,
                      name: user.name,
                      email: user.email
                    }}
                  />
                )
              })}
            </ScrollArea.Viewport>
            <ScrollArea.Scrollbar
              orientation="vertical"
              className="mr-2 w-1 py-2"
            >
              <ScrollArea.Thumb className="w-1 rounded-full bg-cromai-m3-sys-light-outline dark:bg-cromai-m3-sys-dark-outline " />
            </ScrollArea.Scrollbar>
          </ScrollArea.Root>
        </div>
      </Menu.Content>
    </Menu.Root>
  )
}
