import { PropsWithChildren, ReactNode, useState } from 'react'

import * as DialogRaix from '@radix-ui/react-dialog'

import { twMerge } from '@/styles/tailwindMergeConfig'

import { MdClose as CloseIcon } from 'react-icons/md'

type SlideSheetProps = {
  asChild?: boolean
  heading: string
  trigger: ReactNode
} & PropsWithChildren

export function SlideSheet({
  trigger,
  heading,
  children,
  asChild
}: SlideSheetProps) {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)

  return (
    <DialogRaix.Root
      open={isOpenModal}
      onOpenChange={() => setIsOpenModal((state) => !state)}
    >
      <DialogRaix.Trigger asChild={asChild}>{trigger}</DialogRaix.Trigger>
      <DialogRaix.Portal>
        <DialogRaix.Overlay className="absolute inset-0 bg-black opacity-40" />
        <DialogRaix.Content
          aria-describedby={heading}
          className={twMerge(
            `${isOpenModal ? 'modal-slide-open' : 'modal-slide-close'}`,
            'fixed right-0 top-0',
            'h-full w-[29.1875rem] rounded-l-2xl',
            'shadow-lightElevation1 dark:shadow-darkElevation1',
            'bg-cromai-m3-sys-light-background dark:bg-cromai-m3-sys-dark-background'
          )}
        >
          <DialogRaix.Description className="hidden">
            {heading}
          </DialogRaix.Description>
          <div className="flex items-center justify-between px-6 pt-6 text-cromai-m3-sys-light-on-surface-variant dark:text-cromai-m3-sys-dark-on-surface-variant">
            <DialogRaix.Title className="text-titleMedium ">
              {heading}
            </DialogRaix.Title>
            <DialogRaix.Close
              title="Fechar"
              className={twMerge(
                'flex h-8 w-8 items-center justify-center rounded-full hover:bg-[#CCE2D5] hover:dark:bg-[#29432d]'
              )}
            >
              <CloseIcon size={24} className="text-black dark:text-white" />
            </DialogRaix.Close>
          </div>
          <section className="mt-4 flex flex-col px-6">{children}</section>
        </DialogRaix.Content>
      </DialogRaix.Portal>
    </DialogRaix.Root>
  )
}
