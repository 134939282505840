import { apiAthenas } from '@/services/api/apiAthenas'

type UpdateProgressProps = {
  demandId: string
  partId: string
  fileType: string
  progress: number
}
export async function chengeProgressOnUniquePart({
  demandId,
  partId,
  fileType,
  progress
}: UpdateProgressProps) {
  await apiAthenas.put(
    `demands/${demandId}/parts/${partId}/file/${fileType}/progress`,
    { progress }
  )
}
