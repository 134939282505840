import { apiAthenas } from '@/services/api/apiAthenas'

export async function updateDiscordId({
  userId,
  discordId
}: {
  userId: number
  discordId: string
}) {
  return await apiAthenas.put(`/users/${userId}/discord-id/${discordId}`)
}
