import axios from 'axios'
import { FileType } from '@/types/fileTypes'

type PartResultUploadProps = {
  url: string
  zipBlob: Uint8Array
  demandId: string
  fileType: FileType
  partId: string
}

export async function partResultUpload({
  url,
  demandId,
  fileType,
  zipBlob,
  partId
}: PartResultUploadProps) {
  await axios.put(url, zipBlob, {
    headers: {
      'x-goog-meta-demandid': demandId,
      'x-goog-meta-demandfilename': `${fileType}:${demandId}-${partId}-${fileType}`,
      'Content-Type': 'application/zip'
    }
  })
}
