import Providers from '@/providers'
import { Router } from '@/routes/Router'

import { Toastify } from './components'
import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css'

export function App() {
  return (
    <Providers>
      <Toastify />
      <Router />
    </Providers>
  )
}
