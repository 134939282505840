import { Button } from '@/components'
import { useState } from 'react'
import { Stories } from './Stories'

type HistoryProps = {
  boardId?: string
}

export function Histories({ boardId }: HistoryProps) {
  const [isHistory, setIsHistory] = useState<boolean>(false)

  return (
    <div className="mt-6 text-cromai-m3-sys-light-on-background dark:text-cromai-m3-sys-dark-on-background">
      <h1 className="mb-2 text-titleMedium">Histórico do quadro</h1>
      <p className="mb-4 w-[432px] font-mono text-bodySmall">
        Acesse a lista de todas as modificações feitas neste quadro.
      </p>

      {isHistory ? (
        <section className="h-[478px] w-[497px] rounded-lg bg-cromai-m3-sys-light-surface-variant px-4 py-3.5 dark:bg-cromai-m3-sys-dark-surface-variant">
          <div className="flex size-full flex-col gap-4 overflow-auto">
            <Stories boardId={boardId!} />
          </div>
        </section>
      ) : (
        <Button
          disabled={!boardId}
          onClick={() => setIsHistory(true)}
          variant={'outlined'}
          size="xs"
        >
          Exibir histórico
        </Button>
      )}
    </div>
  )
}
