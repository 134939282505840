import { useParams } from 'react-router-dom'

import { deleteAllParts } from '@/services/modules/athenas/demands/part-config/delete-all-parts'
import { useMutation } from '@tanstack/react-query'
import { queryClient } from '@/providers'
import { makeGetFailurePartesKey } from '../queries/useGetFailureParts'
import { handleToast } from '@/utils/handleToast'

export function useDeleteAllParts({ onSuccess }: { onSuccess: () => void }) {
  const { id } = useParams()
  const mutation = useMutation({
    mutationFn: () => deleteAllParts({ demandId: id! }),
    onSuccess: () => {
      queryClient.resetQueries(makeGetFailurePartesKey(id!))

      onSuccess()
    },
    onError: () =>
      handleToast({
        type: 'error',
        message: 'Não foi possível remover as partes. Tente novamente.'
      })
  })

  return mutation
}
