import DatePicker from 'react-datepicker'

import { DatePickerProps } from 'react-datepicker'
import { Input } from '../Input'
import { MdOutlineDateRange } from 'react-icons/md'
import { ptBR } from 'date-fns/locale'
import './custom-datepiker.css'
import { twMerge } from '@/styles/tailwindMergeConfig'

type DatepickerProps = {
  classNameInput?: string
} & DatePickerProps

export function Datepicker({
  classNameInput,
  enableTabLoop = false,
  showPopperArrow = false,
  ...props
}: DatepickerProps) {
  return (
    <DatePicker
      {...props}
      enableTabLoop={enableTabLoop}
      showPopperArrow={showPopperArrow}
      locale={ptBR}
      dateFormat="Pp"
      customTimeInput={
        <input
          type="time"
          className="rounded p-0.5 dark:bg-[#3c3c3c] dark:[color-scheme:dark]"
        />
      }
      customInput={
        <Input
          className={twMerge(classNameInput, 'bg-transparent')}
          icon={<MdOutlineDateRange size={24} />}
        />
      }
    />
  )
}
