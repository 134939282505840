import { getDownloadUrl } from '@/services/modules/athenas/demands/part-config/get-download-url'
import { handleToast } from '@/utils/handleToast'
import { useMutation } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

type GetDownloadUrl = {
  partId: string
  key: string
  value?: string
}

export function usePartFileDownload() {
  const { id } = useParams()

  const mutation = useMutation({
    mutationFn: async ({ key, partId, value }: GetDownloadUrl) => {
      const { data } = await getDownloadUrl({
        demandId: id!,
        key,
        partId,
        value
      })

      const anchorElement = document.createElement('a')
      anchorElement.setAttribute('download', 'title')

      anchorElement.href = data
      anchorElement.click()
    },
    onError: () =>
      handleToast({
        type: 'error',
        message: 'Não foi possível realizar o download. Tente novamente.'
      })
  })

  return mutation
}
