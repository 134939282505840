import { Accordion, Button, FileInputButton } from '@/components'
import { usePartFileDownload } from '@/hooks/demands/mutations/usePartFileDownload'
import { usePartResultDelete } from '@/hooks/demands/mutations/usePartResultDelete'
import { usePartResultUpload } from '@/hooks/demands/mutations/usePartResultUpload'
import { twMerge } from '@/styles/tailwindMergeConfig'
import { Status } from '@/types/demands'
import { FileType } from '@/types/fileTypes'
import { PartFile } from '@/types/Parts'
import { MdAdd as PlusIcon, MdCached as UpdateIcon } from 'react-icons/md'

type UploadResultProps = {
  partId: string
  demandStatus: Status['value']
  title: string
  fileType: FileType
  files: string[]
  downloadReviewedFile: PartFile['downloadReviewedFile']
}

export function UploadResult({
  partId,
  demandStatus,
  fileType,
  files,
  title,
  downloadReviewedFile
}: UploadResultProps) {
  const { mutate: partResultUpload, isLoading: isPartResultUpload } =
    usePartResultUpload()
  const { mutate: partResultDelete } = usePartResultDelete()

  const { mutate: downloadFile } = usePartFileDownload()

  const isNotFinishedDemand = demandStatus !== 'concluded'
  const isFiles = !!files.length

  return (
    <Accordion.Root>
      <Accordion.Item value={fileType} className="mb-3">
        <Accordion.Header disabled={!isFiles}>
          <div className="flex w-full items-center">
            <h1 className="mr-auto flex items-center font-mono text-bodyMedium text-cromai-m3-sys-light-on-surface-variant dark:text-cromai-m3-sys-dark-on-surface-variant">
              {title}
            </h1>
            {isNotFinishedDemand && (
              <FileInputButton
                size="xs"
                variant={isFiles ? 'filled' : 'outlined'}
                label={isFiles ? 'Substituir arquivos' : 'Adicionar arquivos'}
                onFileSelect={(files) =>
                  partResultUpload({
                    partId,
                    fileType,
                    fileList: files!
                  })
                }
                loading={isPartResultUpload}
                multiple
                className={twMerge(
                  'w-[9.1875rem]',
                  isFiles &&
                    'bg-cromai-m3-sys-light-secondary-container text-cromai-m3-sys-light-on-secondary-container dark:bg-cromai-m3-sys-dark-secondary-container dark:text-cromai-m3-sys-dark-on-secondary-container'
                )}
              >
                {isFiles ? <UpdateIcon size={16} /> : <PlusIcon size={16} />}
              </FileInputButton>
            )}
          </div>
        </Accordion.Header>
        <Accordion.Content>
          {isFiles && (
            <div
              className={twMerge(
                'break-all px-2',
                'text-cromai-m3-sys-light-on-surface-variant',
                'dark:text-cromai-m3-sys-dark-on-surface-variant'
              )}
            >
              {files.map((file) => (
                <span
                  className="flex items-center gap-2 px-3 py-2 font-mono text-labelSmall"
                  key={file}
                >
                  {file}
                </span>
              ))}

              <div className="flex justify-center">
                <Button
                  variant="text"
                  onClick={() =>
                    downloadFile({
                      partId,
                      key: downloadReviewedFile!.key,
                      value: downloadReviewedFile!.value
                    })
                  }
                >
                  Baixar arquivos
                </Button>
                {isNotFinishedDemand && (
                  <Button
                    onClick={() => partResultDelete({ fileType, partId })}
                    title="Remover"
                    className="  text-cromai-m3-sys-light-error hover:text-cromai-m3-sys-light-error hover:opacity-80 dark:text-cromai-m3-sys-dark-error"
                    variant="text"
                  >
                    Remover arquivos
                  </Button>
                )}
              </div>
            </div>
          )}
        </Accordion.Content>
      </Accordion.Item>
    </Accordion.Root>
  )
}
