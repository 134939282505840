import { queryClient } from '@/providers'
import { deleteComment } from '@/services/modules/athenas/demands/delete-comment'
import { handleToast } from '@/utils/handleToast'
import { useMutation } from '@tanstack/react-query'
import { useCallback } from 'react'
import { makeGetDemandCommentsKey } from '../queries/useGetDemandComments'
import { produce } from 'immer'
import { Comment } from '@/types/Comment'
import { useParams } from 'react-router-dom'

type UseDeleteCommentProps = {
  commentId: string
}

export function useDeleteComment() {
  const { id } = useParams()

  const deleteCommentFromCache = useCallback(
    ({ commentId }: UseDeleteCommentProps) =>
      queryClient.setQueryData<Comment[]>(
        makeGetDemandCommentsKey(id!),
        produce((draft) => {
          if (!draft) return

          const commentsFiltered = draft.filter(
            (comment) => comment.id !== commentId
          )

          return commentsFiltered
        })
      ),
    [id]
  )

  const mutation = useMutation({
    mutationFn: async ({ commentId }: UseDeleteCommentProps) => {
      deleteCommentFromCache({ commentId })

      await deleteComment({ demandId: id!, commentId })
    },
    onError: () => {
      queryClient.invalidateQueries(makeGetDemandCommentsKey(id!))

      handleToast({
        message: 'Não foi possível deletar o comentário. Tente novamente',
        type: 'error'
      })
    }
  })

  return mutation
}
