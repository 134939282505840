import { getBoardHistory } from '@/services/modules/athenas/boards/get-board-history'
import { handleToast } from '@/utils/handleToast'
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'

const GET_BOARD_HISTORY_KEY = 'GET_BOARD_HISTORY_KEY'

export function makeGetBoardHistoryKey(id: string) {
  return [GET_BOARD_HISTORY_KEY, { id }]
}

export function useBoardHistory(id: string) {
  const query = useQuery({
    queryKey: makeGetBoardHistoryKey(id),
    queryFn: async () => await getBoardHistory(id)
  })

  useEffect(() => {
    if (query.isError) {
      handleToast({
        message:
          'Ocorreu um erro ao carregar o histórico. Tente novamente mais tarde.',
        type: 'error',
        autoClose: false
      })
    }
  }, [query.isError, query.error])

  return query
}
