import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'
import { queryClient } from '@/providers'
import { useParams } from 'react-router-dom'
import { produce } from 'immer'

import { FileType } from '@/types/fileTypes'
import { FailureParts } from '@/types/Parts'

import { handleToast } from '@/utils/handleToast'
import { makeGetFailurePartesKey } from '../queries/useGetFailureParts'
import { removeSessionDuration } from '@/services/modules/athenas/demands/part-config/remove-session-duration'

type RemoveSessionDuration = {
  fileType: FileType
  partId: string
  recordId: string
}

export function useRemoveSessionDuration() {
  const { id } = useParams()

  const removeSessionDurationFromCache = useCallback(
    ({ fileType, partId, recordId }: RemoveSessionDuration) =>
      queryClient.setQueryData<FailureParts>(
        makeGetFailurePartesKey(id!),
        produce((draft) => {
          if (!draft) return

          const partIndex = draft.parts.findIndex((part) => part.id === partId)

          if (fileType === 'planting_failure') {
            const records = draft.parts[
              partIndex
            ].plantingFailures.timeRecords.filter(
              (record) => record.id !== recordId
            )
            draft.parts[partIndex].plantingFailures.timeRecords = records

            return
          }
          const records = draft.parts[
            partIndex
          ].lineReconstruction.timeRecords.filter(
            (record) => record.id !== recordId
          )
          draft.parts[partIndex].lineReconstruction.timeRecords = records

          return
        })
      ),
    [id]
  )

  const mutation = useMutation({
    mutationFn: async ({
      fileType,
      partId,
      recordId
    }: RemoveSessionDuration) => {
      removeSessionDurationFromCache({ fileType, partId, recordId })
      await removeSessionDuration({ demandId: id!, fileType, partId, recordId })
      queryClient.invalidateQueries(makeGetFailurePartesKey(id!))
    },
    onError: () => {
      queryClient.invalidateQueries(makeGetFailurePartesKey(id!))
      handleToast({
        type: 'error',
        message: 'Não foi possível remover o registro. Tente novamente.'
      })
    }
  })

  return mutation
}
