import * as Menu from '@radix-ui/react-dropdown-menu'
import { ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'
type MenuItemProps = {
  label: string
  icon?: ReactNode
} & Menu.DropdownMenuItemProps
export function MenuItem({ label, icon, disabled, ...props }: MenuItemProps) {
  return (
    <Menu.Item
      {...props}
      className={twMerge(
        'flex px-3.5 py-1.5 font-mono text-labelMedium',
        'cursor-pointer outline-none transition-all',
        'text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface ',
        'hover:bg-cromai-m3-sys-light-surface2-hover dark:hover:bg-cromai-m3-sys-dark-surface2-hover',
        disabled && 'pointer-events-none opacity-50'
      )}
    >
      {icon && icon}
      {label}
    </Menu.Item>
  )
}
