import { customTv } from '@/styles/tailwindMergeConfig'

type IndicatorColorProps = {
  color: 'red' | 'orange' | 'yellow' | 'green' | 'blue'
}

const mappingLabelColors = {
  red: 'Vermelho',
  orange: 'Laranja',
  yellow: 'Amarelo',
  green: 'Verde',
  blue: 'Azul'
}

const variants = customTv({
  base: 'size-3.5 rounded-full',
  variants: {
    color: {
      red: 'bg-[#A20F0F] dark:bg-[]',
      orange: 'bg-[#E98C00] dark:bg-[]',
      yellow: 'bg-[#F0CA01] dark:bg-[]',
      green: 'bg-[#006E2C] dark:bg-[#63DE7F]',
      blue: 'bg-[#487BC7] dark:bg-[]'
    }
  }
})

export function IndicatorColor({ color }: IndicatorColorProps) {
  return (
    <figure title={mappingLabelColors[color]} className={variants({ color })} />
  )
}
