import { apiAthenas } from '@/services/api/apiAthenas'
import { FileType } from '@/types/fileTypes'

type DeleteAllPartsProps = {
  demandId: string
  partId: string
  fileType: FileType
}
export async function partResultDelete({
  demandId,
  fileType,
  partId
}: DeleteAllPartsProps) {
  await apiAthenas.delete(
    `demands/${demandId}/parts/${partId}/file/${fileType}`
  )
}
