import { twMerge } from '@/styles/tailwindMergeConfig'

import { FileInputButton } from '@/components'
import { MdUploadFile } from 'react-icons/md'

import { useUploadPeriemter } from '@/hooks/demands/mutations/useUploadPerimeter'
import { ProcessingDialog } from './ProcessingDialog'

export function UploadPerimeter() {
  const { mutate, isLoading } = useUploadPeriemter()

  return (
    <>
      <div
        className={twMerge(
          'flex w-full items-center justify-between gap-4 rounded-lg px-4 py-3.5 font-mono text-bodyMedium',
          'bg-cromai-m3-sys-light-surface dark:bg-cromai-m3-sys-dark-surface'
        )}
      >
        <p>Adicione as partes da demanda para medir o progresso da demanda.</p>
        <FileInputButton
          label="Adicionar partes"
          variant="outlined"
          disabled={isLoading}
          size="xs"
          multiple
          onFileSelect={(files) =>
            mutate({
              fileList: files!,
              fileType: 'perimeter'
            })
          }
        >
          <MdUploadFile size={16} />
        </FileInputButton>
      </div>
      {isLoading && <ProcessingDialog />}
    </>
  )
}
