import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'
import { queryClient } from '@/providers'
import { useParams } from 'react-router-dom'
import { produce } from 'immer'

import { FileType } from '@/types/fileTypes'
import { FailureParts } from '@/types/Parts'

import { makeGetFailurePartesKey } from '../queries/useGetFailureParts'
import { chengeProgressOnUniquePart } from '@/services/modules/athenas/demands/part-config/change-progress-on-unique-part'
import { handleToast } from '@/utils/handleToast'

type ChangeProgressOnUniquePart = {
  fileType: FileType
  partId: string
  progress: number
}

export function useChangeProgressOnUniquePart() {
  const { id } = useParams()

  const changeProgressOnUniquePartFromCache = useCallback(
    ({ fileType, partId, progress }: ChangeProgressOnUniquePart) =>
      queryClient.setQueryData<FailureParts>(
        makeGetFailurePartesKey(id!),
        produce((draft) => {
          if (!draft) return

          const partIndex = draft.parts.findIndex((part) => part.id === partId)

          if (fileType === 'planting_failure') {
            draft.parts[partIndex].plantingFailures.progress = progress
            return
          }
          draft.parts[partIndex].lineReconstruction.progress = progress
          return
        })
      ),
    [id]
  )

  const mutation = useMutation({
    mutationFn: async ({
      fileType,
      partId,
      progress
    }: ChangeProgressOnUniquePart) => {
      await chengeProgressOnUniquePart({
        fileType,
        partId,
        progress,
        demandId: id!
      })

      changeProgressOnUniquePartFromCache({ fileType, partId, progress })
    },
    onError: () =>
      handleToast({
        type: 'error',
        message: 'Não foi possível alterar o progresso. Tente novamente.'
      })
  })

  return mutation
}
