import { Part } from './Part'
import { UploadPerimeter } from '../UploadPerimeter'
import { LoadingCircle } from '@/components'

import { useGetFailureParts } from '@/hooks/demands/queries/useGetFailureParts'
import { AllPartsInfo } from './AllPartsInfo'

export function FalilureParts() {
  const { data, isLoading } = useGetFailureParts()

  const isNotParts = !data?.parts.length

  if (isLoading) {
    return (
      <div className="flex size-full items-center justify-center">
        <LoadingCircle size={24} />
      </div>
    )
  }

  if (isNotParts) return <UploadPerimeter />

  return (
    <div className="w-full min-w-[655px] overflow-auto pr-2">
      <AllPartsInfo
        lineReconstructionProgress={data.lineReconstructionProgress}
        plantingFailuresProgress={data.plantingFailuresProgress}
      />

      <section className="mb-1 mt-4 flex">
        <span className="ml-2">Partes</span>
        <span className="ml-16">Área</span>
        <span className="ml-[85px]">Reconstituição de linhas</span>
        <span className="ml-[90px]">Falhas de plantio</span>
      </section>

      {data.parts.map((part) => {
        return <Part key={part.id} {...part} />
      })}
    </div>
  )
}
