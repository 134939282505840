import { useState } from 'react'

import { Avatar } from '@/components'
import { CommentMenu } from './CommentMenu'
import { CommentEditor } from './CommentEditor'
import { DialogDeleteComment } from './DialogDeleteComment'

import { twMerge } from '@/styles/tailwindMergeConfig'

import { useParseLinks } from './hooks/useParseLinks'
import { useGetUsers } from '@/hooks/demands/queries/useGetUsers'
import { useAuth } from '@/context/auth'

export type CommentProps = {
  userId: number
  commentId: string
  createdAt: string
  updatedAt: string
  comment: string
  commentIndex: number
}

export function Comment({
  userId,
  commentId,
  createdAt,
  updatedAt,
  comment,
  commentIndex
}: CommentProps) {
  const { user: currentUser } = useAuth()
  const [isDialogDeleteComment, setIsDialogDeleteComment] = useState(false)
  const [isEditing, setIsEditing] = useState(false)

  const { data } = useGetUsers()
  const { LinkifiedText } = useParseLinks()

  const user = data?.find((user) => user.id === userId)

  const agent = user ? user.name : 'Not found'

  const isCurrentUser = userId === currentUser.id

  return (
    <section
      className={twMerge(
        'group',
        'flex w-full gap-2 rounded px-4 py-2',
        'transition-all hover:bg-[#cdd3c9] dark:hover:bg-[#2b312b]',
        'font-mono text-cromai-m3-sys-light-on-surface-variant dark:text-cromai-m3-sys-dark-on-surface-variant'
      )}
    >
      <Avatar className="my-1" size="medium" name={agent} />
      <div className="flex w-full flex-col ">
        <span className="flex-wrap text-labelMedium">
          <strong className="">{`${agent} - `}</strong>
          {updatedAt ? `${updatedAt} (Editado)` : createdAt}
        </span>
        <div className="whitespace-pre-wrap break-all">
          {isEditing ? (
            <CommentEditor
              commentId={commentId}
              commentIndex={commentIndex}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              message={comment}
            />
          ) : (
            <LinkifiedText text={comment} />
          )}
        </div>
      </div>
      <CommentMenu
        isCurrentUser={isCurrentUser}
        setIsEtitorComment={setIsEditing}
        setIsDialogDeleteComment={setIsDialogDeleteComment}
      />
      <DialogDeleteComment
        name={agent}
        commentId={commentId}
        isOpen={isDialogDeleteComment}
        setIsOpen={setIsDialogDeleteComment}
      />
    </section>
  )
}
