import { Button, Dialog } from '@/components'
import { useDeleteAllParts } from '@/hooks/demands/mutations/useDeleteAllParts'
import * as DialogRadix from '@radix-ui/react-dialog'

type DeleteAllPartesDialog = {
  setIsOpen: (value: boolean) => void
  isOpen: boolean
}
export function DeleteAllPartsDialog({
  isOpen,
  setIsOpen
}: DeleteAllPartesDialog) {
  const { mutate, isLoading } = useDeleteAllParts({
    onSuccess: () => setIsOpen(false)
  })

  return (
    <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="w-[432px] p-6">
        <DialogRadix.Title className="text-titleLarge text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface">
          Cuidado ao remover as partes
        </DialogRadix.Title>
        <DialogRadix.Description className="mt-2 flex flex-col text-bodyLarge text-cromai-m3-sys-light-on-surface-variant dark:text-cromai-m3-sys-dark-on-surface-variant">
          <span>
            Não será possível recuperar o progresso nem os arquivos revisados.
          </span>
          <span>
            Só faça isso caso o perímetro da demanda tenha sofrido alterações
            gerando um diferente do número de partes e valores de área.
          </span>
        </DialogRadix.Description>
        <div className="mt-6 flex w-full justify-between">
          <DialogRadix.Close asChild>
            <Button variant="outlined">Cancelar</Button>
          </DialogRadix.Close>
          <Button className="w-24" loading={isLoading} onClick={() => mutate()}>
            Remover
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
