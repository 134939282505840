import { useCallback } from 'react'
import { useMutation } from '@tanstack/react-query'
import { queryClient } from '@/providers'

import { useParams } from 'react-router-dom'
import { useAuth } from '@/context/auth'

import { produce } from 'immer'
import { handleToast } from '@/utils/handleToast'

import { updateComment } from '@/services/modules/athenas/demands/update-comment'
import { makeGetDemandCommentsKey } from '../queries/useGetDemandComments'

import { Comment } from '@/types/Comment'

type UpdateCommentProps = {
  commentIndex: number
  commentId: string
  comment: string
}

export function useUpdateComment() {
  const { id } = useParams()
  const { user } = useAuth()

  const updateCommentFromCache = useCallback(
    ({ comment, commentIndex }: UpdateCommentProps) =>
      queryClient.setQueryData<Comment[]>(
        makeGetDemandCommentsKey(id!),
        produce((draft) => {
          if (!draft) return

          draft[commentIndex].message = comment
        })
      ),
    [id]
  )

  const mutation = useMutation({
    mutationFn: async ({
      commentId,
      comment,
      commentIndex
    }: UpdateCommentProps) => {
      updateCommentFromCache({
        commentId,
        comment,
        commentIndex
      })

      await updateComment({
        demandId: id!,
        commentId,
        comment,
        userId: user.id
      })
    },
    onError: () => {
      queryClient.invalidateQueries(makeGetDemandCommentsKey(id!))

      handleToast({
        message: 'Não foi possível atualizar o comentário. Tente novamente',
        type: 'error'
      })
    }
  })

  return mutation
}
