import { apiAthenas } from '@/services/api/apiAthenas'

export async function downloadAllWeedResults({
  demandId,
  userId
}: {
  demandId: string
  userId: number
}) {
  return await apiAthenas.get<string>(`demands/${demandId}/users/${userId}`)
}
