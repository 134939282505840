import * as MenuRadix from '@radix-ui/react-dropdown-menu'

import { twMerge } from 'tailwind-merge'
import { MenuItem } from './MenuItem'
import { ReactNode } from 'react'

type Options = {
  label: string
  icon?: ReactNode
} & MenuRadix.DropdownMenuItemProps

type MenuProps = {
  asChild?: boolean
  trigger: ReactNode
  options: Options[]
  header?: string
  align?: 'center' | 'start' | 'end' | undefined
  side?: 'bottom' | 'top' | 'right' | 'left' | undefined
  className?: string
}

export function Menu({
  trigger,
  asChild,
  header,
  options,
  className
}: MenuProps) {
  return (
    <MenuRadix.Root>
      <MenuRadix.Trigger asChild={asChild}>{trigger}</MenuRadix.Trigger>

      <MenuRadix.Portal>
        <MenuRadix.Content
          align={'start'}
          side={'bottom'}
          className={twMerge(
            'flex flex-col justify-center py-2',
            'mt-1 rounded',
            'font-mono',
            'bg-cromai-m3-sys-light-surface2 dark:bg-cromai-m3-sys-dark-surface2',
            'text-cromai-m3-sys-light-on-surface-variant dark:text-cromai-m3-sys-dark-on-surface-variant',
            'shadow-lightElevation2 dark:shadow-darkElevation2',
            className
          )}
        >
          {header && <h1 className="px-3.5 py-1.5">{header}</h1>}
          {options.map((option) => (
            <MenuItem key={option.label} {...option} />
          ))}
        </MenuRadix.Content>
      </MenuRadix.Portal>
    </MenuRadix.Root>
  )
}
