import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

import { useUpdateComment } from '@/hooks/demands/mutations/useUpdateComment'
import { useOutsideClickAndEscape } from '../hooks/useOutsideClickAndEscape'

import { AutoResizableTextarea } from '@/components'

const commentSchema = z
  .object({
    message: z.string()
  })
  .superRefine((val, ctx) => {
    const isInvalidMessage = !val.message.trim()

    if (isInvalidMessage) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'invalid message',
        path: ['message']
      })
    }
  })

type CommentSchema = z.infer<typeof commentSchema>

type CommentEditorProps = {
  message: string
  isEditing: boolean
  setIsEditing: (value: boolean) => void
  commentId: string
  commentIndex: number
}

export function CommentEditor({
  message,
  setIsEditing,
  commentId,
  commentIndex
}: CommentEditorProps) {
  const { ref } = useOutsideClickAndEscape({
    onClose: () => setIsEditing(false)
  })

  const { mutate: updateComment } = useUpdateComment()

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors }
  } = useForm<CommentSchema>({
    resolver: zodResolver(commentSchema),
    mode: 'onChange'
  })

  const onSubmit = handleSubmit((data, event) => {
    event?.preventDefault()

    if (message === data.message) {
      setIsEditing(false)
      return
    }
    updateComment({ comment: data.message, commentId, commentIndex })

    setIsEditing(false)
  })

  const handleEnterSubmit = (
    event: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    const currentMessage = getValues('message').trim()

    if (event.key === 'Enter' && !event.shiftKey) {
      if (!currentMessage) {
        event.preventDefault()
        return
      }
      event.preventDefault()
      onSubmit(event as unknown as React.BaseSyntheticEvent)
    }
  }

  return (
    <form
      ref={ref}
      className="relative mt-1 flex max-h-80 w-full items-end gap-4"
    >
      <Controller
        name="message"
        control={control}
        defaultValue={message}
        render={({ field: { onChange, value } }) => (
          <AutoResizableTextarea
            maxLength={2000}
            onKeyDown={handleEnterSubmit}
            isError={!!errors.message}
            value={value}
            onChange={onChange}
            className="min-h-12 rounded-lg"
            classTextarea="w-full my-2 h-full py- pl-4 max-h-48 pr-40"
          />
        )}
      />
    </form>
  )
}
