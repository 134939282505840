export default {
  root: '/',
  logout: '/logout',
  recovery: '/recovery',
  profileSettings: '/profile-settings',
  demands: '/demands',
  demand: '/demands/:id',
  frame: '/boards/:id',
  boardSettings: '/boards/settings'
} as const
