import { apiAthenas } from '@/services/api/apiAthenas'
import { FileType } from '@/types/fileTypes'

export async function genereteUrlUploadPeriemter({
  demandId,
  fileType
}: {
  demandId: string
  fileType: FileType
}) {
  const { data } = await apiAthenas.get<string>(
    `/demands/${demandId}/upload/${fileType}`
  )
  return data
}
