import * as DialogRadix from '@radix-ui/react-dialog'

import {
  MdClose as CloseIcon,
  MdCheckCircle,
  MdErrorOutline
} from 'react-icons/md'

import { Avatar, Button, Dialog } from '@/components'
import { useGetFailureParts } from '@/hooks/demands/queries/useGetFailureParts'
import { twMerge } from '@/styles/tailwindMergeConfig'
import { useUserInfos } from '@/hooks/useUserInfos'
import { useGrowLineDelivery } from '@/hooks/demands/mutations/useGrowLineDelivery'
import { useGetDemand } from '@/hooks/useGetDemand'

interface GrowLinesDeliveryDialogProps {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
}

export function GrowLinesDeliveryDialog({
  isOpen,
  setIsOpen
}: GrowLinesDeliveryDialogProps) {
  const { data: failureParts } = useGetFailureParts()
  const { data: demand } = useGetDemand()
  const { findUserInfo } = useUserInfos()

  const { mutate, isLoading } = useGrowLineDelivery({
    onSuccess: () => setIsOpen(false)
  })

  const allPartsWithoutResults = failureParts?.parts.filter((part) => {
    const isNotLineReconstruction = !part.lineReconstruction.fileList.length
    const isNotPlantingFailures = !part.plantingFailures.fileList.length

    if (isNotLineReconstruction) return true
    if (isNotPlantingFailures) return true

    return false
  })

  const parts = allPartsWithoutResults?.length
    ? allPartsWithoutResults
    : failureParts?.parts

  if (!failureParts?.parts.length) {
    return (
      <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className=" flex max-h-[27rem] w-[28.125rem] flex-col p-6 text-black dark:text-white">
          <div className="mb-6 flex items-center justify-between ">
            <DialogRadix.Title className="font-mono text-titleLarge">
              Não é possível entregar a demanda
            </DialogRadix.Title>

            <DialogRadix.Close title="Fechar">
              <CloseIcon size={24} />
            </DialogRadix.Close>
          </div>
          <DialogRadix.Description className="text-cromai-m3-sys-light-on-surface-variant dark:text-cromai-m3-sys-dark-on-surface-variant">
            Adicione um perímetro para que as partes sejam criadas.
          </DialogRadix.Description>
        </div>
      </Dialog>
    )
  }

  return (
    <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className=" flex max-h-[27rem] w-[37.5rem] flex-col py-6 pl-6 pr-5">
        {allPartsWithoutResults?.length ? (
          <div className="mb-6 pr-1 text-black dark:text-white">
            <DialogRadix.Title className="mb-4 font-mono text-titleLarge ">
              Não é possível entregar a demanda
            </DialogRadix.Title>
            <DialogRadix.Description className="text-bodyMedium text-cromai-m3-sys-light-on-surface-variant dark:text-cromai-m3-sys-dark-on-surface-variant">
              Verifique os arquivos de resultados.
            </DialogRadix.Description>
          </div>
        ) : (
          <DialogRadix.Title className="mb-6 flex w-full flex-row items-start justify-between gap-5 pr-1 text-black dark:text-white">
            <DialogRadix.Description>
              Você tem certeza que deseja entregar a demanda{' '}
              <strong>{`${demand?.demandName}?`}</strong>
            </DialogRadix.Description>
            <DialogRadix.Close title="Fechar">
              <CloseIcon size={24} />
            </DialogRadix.Close>
          </DialogRadix.Title>
        )}

        <div className="max-h-[17rem] w-full overflow-auto pr-1">
          <div className="flex w-full flex-col gap-2">
            {parts?.map((part) => {
              const isLineFileList = !!part.lineReconstruction.fileList.length
              const isFailureFileList = !!part.plantingFailures.fileList.length

              const lineUser = findUserInfo(part.lineReconstruction.userId)
              const failureUser = findUserInfo(part.plantingFailures.userId)

              return (
                <section
                  key={part.id}
                  className={twMerge(
                    'flex items-center gap-8 px-2 py-2',
                    'bg-cromai-m3-sys-light-background  text-cromai-m3-sys-light-on-background',
                    'dark:bg-cromai-m3-sys-dark-background dark:text-cromai-m3-sys-dark-on-background',
                    'rounded-lg'
                  )}
                >
                  <div className="flex gap-4">
                    <span className="w-6 text-end">{part.partNumber}</span>
                    <span className="w-[3.75rem] text-end">
                      {part.area.toFixed(1)}
                    </span>
                  </div>

                  <div className="flex items-center gap-2">
                    <span className="w-12">Linhas</span>
                    {isLineFileList ? (
                      <MdCheckCircle
                        size={20}
                        title="Resultados entregue"
                        className="text-cromai-source-primary dark:text-cromai-m3-sys-dark-primary"
                      />
                    ) : (
                      <MdErrorOutline
                        size={20}
                        title="Falta de resultados"
                        className="text-cromai-m3-ref-error-40 dark:text-cromai-m3-ref-error-60"
                      />
                    )}
                    {lineUser ? (
                      <Avatar name={lineUser.name} size="small" />
                    ) : (
                      <div className="size-6" />
                    )}
                  </div>

                  <div className="flex items-center gap-2">
                    <span className="w-12">Falhas</span>
                    {isFailureFileList ? (
                      <MdCheckCircle
                        size={20}
                        title="Resultados entregue"
                        className="text-cromai-source-primary dark:text-cromai-m3-sys-dark-primary"
                      />
                    ) : (
                      <MdErrorOutline
                        size={20}
                        title="Falta de resultados"
                        className="text-cromai-m3-ref-error-40 dark:text-cromai-m3-ref-error-60"
                      />
                    )}
                    {failureUser ? (
                      <Avatar name={failureUser.name} size="small" />
                    ) : (
                      <div className="size-6" />
                    )}
                  </div>
                </section>
              )
            })}
          </div>
        </div>

        {allPartsWithoutResults?.length ? (
          <div className="mt-6 flex justify-end pr-1">
            <DialogRadix.Close asChild>
              <Button variant="outlined">Cancelar</Button>
            </DialogRadix.Close>
          </div>
        ) : (
          <div className="mt-6 flex justify-between pr-1">
            <DialogRadix.Close asChild>
              <Button variant="outlined">Cancelar</Button>
            </DialogRadix.Close>
            <Button
              className="w-[5.8125rem]"
              onClick={() => mutate()}
              loading={isLoading}
            >
              Entregar
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  )
}
