import * as Popover from '@radix-ui/react-popover'

import { twMerge } from 'tailwind-merge'

import {
  MdEditNote,
  MdOutlineDelete,
  MdMoreVert as TreeDotsIcon
} from 'react-icons/md'

type CommentMenuProps = {
  isCurrentUser: boolean
  setIsEtitorComment: (value: boolean) => void
  setIsDialogDeleteComment: (value: boolean) => void
}

export function CommentMenu({
  isCurrentUser,
  setIsEtitorComment,
  setIsDialogDeleteComment
}: CommentMenuProps) {
  return (
    <Popover.Root>
      <Popover.Trigger
        aria-label="Menu"
        title="Opções"
        className={twMerge(
          'hidden group-hover:flex',
          'my-1 ml-auto size-8 shrink-0 items-center justify-center rounded-full',
          'transition-colors hover:bg-[#bdc2b9] dark:hover:bg-cromai-m3-sys-dark-surface-variant'
        )}
      >
        <TreeDotsIcon
          role="figure"
          size={24}
          className="text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface"
        />
      </Popover.Trigger>
      <Popover.Content
        side="top"
        align="end"
        alignOffset={8}
        sideOffset={-25}
        className="flex flex-col rounded bg-cromai-m3-sys-light-surface2 py-1.5 shadow-lightElevation2 dark:bg-cromai-m3-sys-dark-surface2 dark:shadow-darkElevation2"
      >
        {isCurrentUser && (
          <Popover.Close asChild>
            <button
              onClick={() => setIsEtitorComment(true)}
              className={twMerge(
                'flex items-center gap-3 px-3 py-1.5',
                'hover:bg-cromai-m3-sys-light-surface2-hover dark:hover:bg-cromai-m3-sys-dark-surface2-hover',
                'text-cromai-m3-sys-light-inverse-surface dark:text-cromai-m3-sys-dark-inverse-surface'
              )}
            >
              <MdEditNote role="figure" size={20} />
              Editar
            </button>
          </Popover.Close>
        )}
        <Popover.Close asChild>
          <button
            onClick={() => setIsDialogDeleteComment(true)}
            className={twMerge(
              'flex items-center gap-3 px-3 py-1.5',
              'hover:bg-cromai-m3-sys-light-surface2-hover dark:hover:bg-cromai-m3-sys-dark-surface2-hover',
              'text-cromai-m3-sys-light-inverse-surface dark:text-cromai-m3-sys-dark-inverse-surface'
            )}
          >
            <MdOutlineDelete role="figure" size={20} />
            Apagar
          </button>
        </Popover.Close>
      </Popover.Content>
    </Popover.Root>
  )
}
