import { useMutation } from '@tanstack/react-query'

import { handleToast } from '@/utils/handleToast'

import { useParams } from 'react-router-dom'

import { downloadAllWeedResults } from '@/services/modules/athenas/demands/download-all-weed-results'

export function useDownloadAllWeedResults() {
  const { id } = useParams()

  const mutation = useMutation({
    mutationFn: async ({ userId }: { userId: number }) => {
      const { data } = await downloadAllWeedResults({
        demandId: id!,
        userId
      })

      const anchorElement = document.createElement('a')
      anchorElement.setAttribute('download', 'title')

      anchorElement.href = data
      anchorElement.click()
    },
    onError: () =>
      handleToast({
        type: 'error',
        message: 'Não foi possível realizar o download. Tente novamente.'
      })
  })

  return mutation
}
