import { useMutation } from '@tanstack/react-query'
import { queryClient } from '@/providers'
import { useParams } from 'react-router-dom'

import { FileType } from '@/types/fileTypes'

import { makeGetFailurePartesKey } from '../queries/useGetFailureParts'
import { addSessionDuration } from '@/services/modules/athenas/demands/part-config/add-session-duration'
import { handleToast } from '@/utils/handleToast'

type AddSessionDuration = {
  date: string
  fileType: FileType
  partId: string
  time: string
}

export function useAddSessionDuration() {
  const { id } = useParams()

  const mutation = useMutation({
    mutationFn: async ({
      fileType,
      partId,
      date,
      time
    }: AddSessionDuration) => {
      await addSessionDuration({ demandId: id!, date, fileType, partId, time })

      queryClient.invalidateQueries(makeGetFailurePartesKey(id!))
    },
    onError: () =>
      handleToast({
        type: 'error',
        message: 'Não foi possível registrar a sessão. Tente novamente.'
      })
  })

  return mutation
}
