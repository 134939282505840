import { getDemand } from '@/services/modules/athenas/demands'
import { handleToast } from '@/utils/handleToast'
import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'

export const GET_DEMAND_KEY = 'GET_DEMAND'

export function makeGetDemandKey(id: string) {
  return [GET_DEMAND_KEY, { id }]
}

export function useGetDemand() {
  const { id } = useParams()

  const query = useQuery({
    queryKey: makeGetDemandKey(id!),
    queryFn: () => getDemand(id!),
    onError: () =>
      handleToast({
        message: 'Não foi possível carregar a página. Tente novamente.',
        type: 'error',
        autoClose: false
      })
  })

  return query
}
