import { useMemo } from 'react'

export function useParseLinks() {
  const parseTextWithLinks = (text: string) => {
    const urlPattern = /(https?:\/\/[^\s]+)/g

    const textParts = text.split(urlPattern)

    return textParts.map((partOfTheText, index) => {
      if (urlPattern.test(partOfTheText)) {
        return (
          <a
            className="text-cromai-m3-sys-light-tertiary underline dark:text-cromai-m3-sys-dark-tertiary"
            key={index}
            href={partOfTheText}
            target="_blank"
            rel="noopener noreferrer"
          >
            {partOfTheText}
          </a>
        )
      }
      return partOfTheText
    })
  }

  const LinkifiedText = ({ text }: { text: string }) => {
    const formattedText = useMemo(() => parseTextWithLinks(text), [text])

    return <>{formattedText}</>
  }

  return { LinkifiedText }
}
