import { useRemoveSessionDuration } from '@/hooks/demands/mutations/useRemoveSessionDuration'
import { FileType } from '@/types/fileTypes'
import { PartFile } from '@/types/Parts'
import { MdClear } from 'react-icons/md'
import { twMerge } from 'tailwind-merge'

type TimeRecords = {
  records: PartFile['timeRecords']
  fileType: FileType
  partId: string
}

export function TimeRecords({ records, fileType, partId }: TimeRecords) {
  const { mutate } = useRemoveSessionDuration()
  return (
    <>
      <h2
        className={twMerge(
          'mt-6 text-titleSmall',
          'text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface'
        )}
      >
        Registros
      </h2>

      {records.map((record) => {
        return (
          <section
            key={`${record.id}`}
            className="flex w-full items-center justify-between "
          >
            <div
              className={twMerge(
                'mt-2 flex items-center gap-4 py-2.5 font-mono',
                'text-cromai-m3-sys-light-on-background dark:text-cromai-m3-sys-dark-on-background'
              )}
            >
              <span className="text-labelLarge">{record.time}</span>
              <span className="text-bodyMedium">em {record.date}</span>
            </div>
            <button
              title="Remover"
              onClick={() => mutate({ fileType, partId, recordId: record.id })}
            >
              <MdClear
                className={twMerge(
                  'transition-all hover:opacity-80',
                  'text-cromai-m3-sys-light-on-surface-variant dark:text-cromai-m3-sys-dark-on-surface-variant'
                )}
                size={20}
              />
            </button>
          </section>
        )
      })}
    </>
  )
}
