import {
  Avatar,
  Button,
  ProgressBar,
  Scrollarea,
  SlideSheet
} from '@/components'
import { PartFile } from '@/types/Parts'
import { MdOutlinePersonRemove, MdUploadFile } from 'react-icons/md'
import { twMerge } from 'tailwind-merge'

import { useState } from 'react'
import { FileType } from '@/types/fileTypes'
import { useBreakpoint } from '@/hooks/useBreakpoint'
import { User } from '@/types/User'

import { RemoveUserDialog } from './RemoveUserDialog'
import { UploadResult } from './UploadResult'
import { AddUserMenu } from '../AddUserMenu'
import { ProgressForm } from './ProgressForm'
import { TimeForm } from './TimeForm'
import { TimeRecords } from './TimeRecords'

type UnitPartConfigProps = {
  user: User | undefined
  area: number
  partId: string
  partNumber: number
  fileType: FileType
} & Partial<PartFile>

const MappingTitleSlideSheet = {
  reconstruction_lines: 'Reconstituição de linhas',
  planting_failure: 'Falha de plantio'
}

export function UnitPartConfig({
  area,
  user,
  partId,
  fileList,
  fileType,
  partNumber,
  progress,
  timeRecords,
  totalRevisionTime,
  downloadReviewedFile
}: UnitPartConfigProps) {
  const [isRemoveUserDialog, setIsRemoveUserDialog] = useState(false)

  const [isLg] = useBreakpoint('lg')

  const isFileList = !!fileList!.length

  const isUser = !!user

  return (
    <>
      <SlideSheet
        heading={`Parte ${partNumber} - ${MappingTitleSlideSheet[fileType]}`}
        asChild
        trigger={
          <Button
            title="Configurações"
            className={twMerge(
              'shrink-0',
              isFileList &&
                'bg-cromai-m3-sys-light-secondary-container text-cromai-m3-sys-light-on-secondary-container dark:bg-cromai-m3-sys-dark-secondary-container dark:text-cromai-m3-sys-dark-on-secondary-container'
            )}
            size="icon"
            variant={isFileList ? 'filled' : 'outlined'}
          >
            <MdUploadFile size={12} />
          </Button>
        }
      >
        <Scrollarea className="h-[calc(100vh-6rem)] overflow-auto">
          <div className="flex gap-4 py-2.5 font-mono text-labelLarge text-cromai-m3-sys-light-on-surface-variant dark:text-cromai-m3-sys-dark-on-surface-variant">
            <span>{area.toFixed(1)} ha</span>
            <span className="flex items-center gap-1">
              {progress}% <ProgressBar progress={progress!} />
            </span>
            <span>{totalRevisionTime}</span>
          </div>

          <div className="mb-6 mt-2 flex py-2 text-cromai-m3-sys-light-on-background dark:text-cromai-m3-sys-dark-on-background">
            {isUser ? (
              <>
                <div className="flex items-center gap-2">
                  <Avatar size="small" name={user.name} />
                  <span className="font-mono text-bodyMedium">{user.name}</span>
                </div>
                <button
                  title={`Remover ${user.name}`}
                  className="ml-auto"
                  onClick={() => setIsRemoveUserDialog(true)}
                >
                  <MdOutlinePersonRemove size={20} />
                </button>
              </>
            ) : (
              <div className="w-full ">
                {isLg ? (
                  <AddUserMenu
                    fileType={fileType}
                    partId={partId}
                    side="left"
                    align="start"
                    sideOffset={36}
                    alignOffset={-52}
                  />
                ) : (
                  <AddUserMenu
                    fileType={fileType}
                    partId={partId}
                    side="bottom"
                    align="end"
                    sideOffset={-110}
                    alignOffset={-20}
                  />
                )}
              </div>
            )}
          </div>

          <UploadResult
            demandStatus="processed"
            partId={partId}
            fileType={fileType}
            files={fileList!}
            title={MappingTitleSlideSheet[fileType]}
            downloadReviewedFile={downloadReviewedFile!}
          />

          {isUser && (
            <ProgressForm
              fileType={fileType}
              partId={partId}
              progress={progress!}
            />
          )}

          {isUser && <TimeForm fileType={fileType} partId={partId} />}

          {isUser && (
            <TimeRecords
              fileType={fileType}
              partId={partId}
              records={timeRecords!}
            />
          )}
        </Scrollarea>
      </SlideSheet>
      {isRemoveUserDialog && (
        <RemoveUserDialog
          partId={partId}
          fileType={fileType}
          isOpen={isRemoveUserDialog}
          setIsOpen={setIsRemoveUserDialog}
          partNumber={partNumber}
          user={user}
        />
      )}
    </>
  )
}
