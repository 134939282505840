import { Chip } from '@/components'
import { useFiltersApplied } from '@/hooks/useFiltersApplied'
import { useEffect, useLayoutEffect, useRef, useState, useMemo } from 'react'

export function FilterDisplay() {
  const containerRef = useRef<HTMLDivElement>(null)
  const [containerWidth, setContainerWidth] = useState(0)
  const [visibleLabels, setVisibleLabels] = useState<string[]>([])
  const [remainingLabels, setRemainingLabels] = useState<string[]>([])

  const { filters } = useFiltersApplied()

  const measureTextWidth = (text: string, font: string) => {
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    if (context) {
      context.font = font
      return context.measureText(text).width
    }
    return 0
  }

  // Atualiza o containerWidth ao montar o componente e quando a janela for redimensionada
  useLayoutEffect(() => {
    const updateContainerWidth = () => {
      if (containerRef.current) {
        const newWidth = containerRef.current.offsetWidth
        if (newWidth !== containerWidth) {
          setContainerWidth(newWidth)
        }
      }
    }

    updateContainerWidth() // Atualiza quando o componente monta
    window.addEventListener('resize', updateContainerWidth) // Atualiza no resize da janela

    // Cleanup do listener quando o componente desmonta
    return () => {
      window.removeEventListener('resize', updateContainerWidth)
    }
  }, [containerWidth]) // Incluímos containerWidth como dependência para evitar loops desnecessários

  const calculateLabels = useMemo(() => {
    let currentWidth = 0
    const visible: string[] = []
    const hidden: string[] = []

    const chipFont = '11px monospace'
    const chipPadding = 43
    const maxCharacters = 20
    const truncatedSuffix = '...'

    filters.forEach((label) => {
      const truncatedLabel =
        label.length > maxCharacters
          ? `${label.slice(0, maxCharacters)}${truncatedSuffix}`
          : label
      const chipWidth = measureTextWidth(truncatedLabel, chipFont) + chipPadding

      if (currentWidth + chipWidth <= containerWidth) {
        currentWidth += chipWidth
        visible.push(truncatedLabel)
      } else {
        hidden.push(label)
      }
    })

    return { visible, hidden }
  }, [filters, containerWidth]) // Atualiza somente quando filters ou containerWidth mudam

  useEffect(() => {
    const { visible, hidden } = calculateLabels

    // Atualiza apenas se o novo valor for diferente do estado atual
    if (JSON.stringify(visible) !== JSON.stringify(visibleLabels)) {
      setVisibleLabels(visible)
    }
    if (JSON.stringify(hidden) !== JSON.stringify(remainingLabels)) {
      setRemainingLabels(hidden)
    }
  }, [calculateLabels, visibleLabels, remainingLabels])

  return (
    <div ref={containerRef} className="flex w-full gap-2 overflow-hidden">
      {visibleLabels.map((filter) => (
        <Chip key={filter} label={filter} tooltipText={filter} />
      ))}
      {remainingLabels.length > 0 && (
        <Chip label={`+${remainingLabels.length} filtros`} tooltipText="" />
      )}
    </div>
  )
}
