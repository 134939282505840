import { getDemandComments } from '@/services/modules/athenas/demands/get-demand-comments'
import { handleToast } from '@/utils/handleToast'
import { useQuery } from '@tanstack/react-query'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

export const GET_DEMAND_COMMENTS_KEY = 'GET_DEMAND_COMMENTS'

export function makeGetDemandCommentsKey(id: string) {
  return [GET_DEMAND_COMMENTS_KEY, { id }]
}

export function useGetDemandComments() {
  const { id } = useParams()

  const query = useQuery({
    queryKey: makeGetDemandCommentsKey(id!),
    queryFn: () => getDemandComments(id!)
  })

  useEffect(() => {
    if (query.isError) {
      handleToast({
        message:
          'Ocorreu um erro ao carregar os comentários. Tente novamente mais tarde.',
        type: 'error',
        autoClose: false
      })
    }
  }, [query.isError, query.error])

  return query
}
