import { customTv, twMerge } from '@/styles/tailwindMergeConfig'
import { useEffect, useRef } from 'react'

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string
  isError?: boolean
  classTextarea: string
}

const variants = customTv({
  base: [
    'flex relative w-full justify-between items-center',
    'bg-cromai-m3-sys-light-surface dark:bg-cromai-m3-sys-dark-surface'
  ].join(' '),

  variants: {
    withBorder: [
      'border',
      'border-cromai-m3-sys-light-outline dark:border-cromai-m3-sys-dark-outline',
      'focus:border-cromai-m3-sys-light-primary dark:focus:border-cromai-m3-sys-dark-primary',
      'focus:outline-none focus:ring-transparent'
    ].join(' '),
    borderError: {
      true: [
        'border',
        'border-cromai-m3-ref-error-30 dark:border-cromai-m3-ref-error-40',
        'focus:border-cromai-m3-ref-error-30 dark:focus:border-cromai-m3-ref-error-50'
      ].join(' ')
    }
  }
})

export function AutoResizableTextarea({
  isError,
  className,
  classTextarea,
  children,
  onChange,
  value,
  ...props
}: TextareaProps) {
  const textareaRef = useRef<HTMLTextAreaElement | null>(null)

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '20px'
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
    }
  }, [value])

  return (
    <label
      data-testid="label-auto-resize"
      className={variants({ className, borderError: isError })}
    >
      <textarea
        {...props}
        ref={textareaRef}
        onChange={onChange}
        value={value}
        className={twMerge(
          classTextarea,
          'font-mono text-bodyMedium font-normal leading-tight',
          'flex resize-none items-center justify-center bg-transparent outline-none'
        )}
      />
      {children}
    </label>
  )
}
