import { twMerge } from '@/styles/tailwindMergeConfig'

import { Options } from './Options'
import { PopoverReviewers } from './PopeverReviewers'
import { Avatar, Checkbox, Scrollarea } from '@/components'

import { useBreakpoint } from '@/hooks/useBreakpoint'
import { useGetDemand } from '@/hooks/useGetDemand'
import { usePutReviewFinished } from '@/hooks/usePutReviewFinished'
import { useUpdateIsQACheckedOnResponsible } from '@/hooks/demands/mutations/useUpdateIsQACheckedOnResponsible'

export function Responsible() {
  const { data } = useGetDemand()
  const { putReviewFinished } = usePutReviewFinished()
  const { mutate: updateIsQAChecked } = useUpdateIsQACheckedOnResponsible()

  const [isLg] = useBreakpoint('lg')

  const isDemandCompleted = data!.status.value === 'concluded'

  return (
    <div
      className={twMerge(
        'bg-cromai-m3-sys-light-surface-variant text-cromai-m3-sys-light-on-background',
        'dark:bg-cromai-m3-sys-dark-surface-variant dark:text-cromai-m3-sys-dark-on-background',
        ' flex min-h-[400px] w-full flex-col items-start overflow-auto rounded px-6 py-4 font-mono text-labelLarge lg:h-full'
      )}
    >
      <div className="w-full ">
        {isLg ? (
          <PopoverReviewers
            side="left"
            align="start"
            sideOffset={36}
            alignOffset={-52}
          />
        ) : (
          <PopoverReviewers
            side="bottom"
            align="end"
            sideOffset={-110}
            alignOffset={-20}
          />
        )}
      </div>
      <Scrollarea className="w-full overflow-hidden">
        {data!.userList.map((reviewer) => {
          return (
            <div
              key={reviewer.userId}
              className="mb-2 flex w-full items-center rounded bg-cromai-m3-sys-light-background py-2 pl-4 pr-2 dark:bg-cromai-m3-sys-dark-background"
            >
              <div className="mr-auto flex items-center gap-2">
                <Avatar size="small" name={reviewer.userName} />
                <span className="max-w-44 truncate">{reviewer.userName}</span>
              </div>

              {reviewer.isQA && (
                <span className="ml-auto flex items-center gap-1">
                  <Checkbox
                    title="QA"
                    disabled={isDemandCompleted}
                    checked={reviewer.isQAChecked}
                    onCheckedChange={() =>
                      updateIsQAChecked({
                        demandId: data!.id,
                        reviewerId: reviewer.userId,
                        isQAChecked: !reviewer.isQAChecked
                      })
                    }
                  />
                  QA
                </span>
              )}

              <span className="ml-2 flex items-center gap-1">
                <Checkbox
                  title="Demanda revisada"
                  disabled={isDemandCompleted}
                  checked={reviewer.isFinish}
                  onCheckedChange={() =>
                    putReviewFinished.mutate({
                      demandId: data!.id,
                      userId: reviewer.userId,
                      isFinish: reviewer.isFinish ? !reviewer.isFinish : true
                    })
                  }
                />
                Rev
              </span>

              <Options reviewer={reviewer} />
            </div>
          )
        })}
      </Scrollarea>
    </div>
  )
}
