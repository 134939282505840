import { apiAthenas } from '@/services/api/apiAthenas'
import { FileType } from '@/types/fileTypes'

type GetUrlForUploadFilesrProps = {
  demandId: string
  partId: string
  fileType: FileType
}

export async function getUrlForUploadFiles({
  demandId,
  partId,
  fileType
}: GetUrlForUploadFilesrProps) {
  const { data } = await apiAthenas.get<string>(
    `demands/${demandId}/parts/${partId}/file/${fileType}`
  )

  return data
}
