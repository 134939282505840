import { apiAthenas } from '@/services/api/apiAthenas'
import { AtlasData } from '@/types/AtlasData'

export async function getAtlasData(demandId: string) {
  const { data } = await apiAthenas.get<AtlasData[]>(
    `demands/${demandId}/atlasData`
  )

  return data
}
