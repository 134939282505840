import { AutoResizableTextarea, Button } from '@/components'
import { MdAttachFile } from 'react-icons/md'

import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

import { useAddNewComment } from '@/hooks/demands/mutations/useAddNewComment'

const commentSchema = z
  .object({
    message: z.string()
  })
  .superRefine((val, ctx) => {
    const isInvalidMessage = !val.message.trim()

    if (isInvalidMessage) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'invalid message',
        path: ['message']
      })
    }
  })

type CommentSchema = z.infer<typeof commentSchema>

export function CommentForm() {
  const { mutate: addNewComment, isLoading: isAddNewComment } =
    useAddNewComment()

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors }
  } = useForm<CommentSchema>({
    defaultValues: { message: '' },
    resolver: zodResolver(commentSchema),
    mode: 'onChange'
  })

  const onSubmit = handleSubmit(({ message }, event) => {
    event?.preventDefault()
    addNewComment({ message })

    setValue('message', '')
  })

  const handleEnterSubmit = (
    event: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    const currentMessage = getValues('message').trim()

    if (event.key === 'Enter' && !event.shiftKey) {
      if (!currentMessage) {
        event.preventDefault()
        return
      }
      event.preventDefault()
      onSubmit(event as unknown as React.BaseSyntheticEvent)
    }
  }
  return (
    <form
      onSubmit={onSubmit}
      className="relative mt-auto flex max-h-80 w-full items-end gap-4"
    >
      <Controller
        name="message"
        control={control}
        defaultValue={''}
        render={({ field: { onChange, value } }) => (
          <AutoResizableTextarea
            placeholder="Envie um comentário ou link"
            maxLength={2000}
            value={value}
            onChange={onChange}
            isError={!!errors.message}
            onKeyDown={(e) => handleEnterSubmit(e)}
            className="min-h-12 rounded-lg"
            classTextarea="w-full my-2 h-full py- pl-4 max-h-80 pr-40"
          />
        )}
      />
      <div className="absolute bottom-2 right-4 flex items-center gap-4">
        <MdAttachFile
          className="opacity-30 dark:text-white"
          title="Anex..., ainda não 🙃"
          size={24}
        />
        <Button
          size="xs"
          className="w-20"
          variant="outlined"
          loading={isAddNewComment}
        >
          Enviar
        </Button>
      </div>
    </form>
  )
}
