import { apiAthenas } from '@/services/api/apiAthenas'

export async function deleteComment({
  demandId,
  commentId
}: {
  demandId: string
  commentId: string
}) {
  await apiAthenas.delete(`demands/${demandId}/comment/${commentId}`)
}
