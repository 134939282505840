import { apiAthenas } from '@/services/api/apiAthenas'
import { FileType } from '@/types/fileTypes'

type RemoveUserProps = {
  demandId: string
  partId: string
  fileType: FileType
}

export async function removeUserOnUniquePart({
  demandId,
  partId,
  fileType
}: RemoveUserProps) {
  await apiAthenas.delete(
    `demands/${demandId}/parts/${partId}/file/${fileType}/user`
  )
}
