import * as DialogRadix from '@radix-ui/react-dialog'
import * as ScrollArea from '@radix-ui/react-scroll-area'

import {
  MdClose as CloseIcon,
  MdOutlineErrorOutline,
  MdOutlineExtension
} from 'react-icons/md'

import { Accordion, Button, Dialog } from '@/components'
import { useDeliveryDemand } from '@/hooks/useDeliveryDemand'
import { useGetDemand } from '@/hooks/useGetDemand'
import { AccordionStyled } from '../AccordionStyled'
import { Fragment } from 'react'
import { useAllFilesPerReviewers } from './hooks/useAllFilesPerReviewers'
import { useAllPartsOfReviewers } from './hooks/useAllPartsOfReviewers'
import { useAllPartsWithoutResults } from './hooks/useAllPartsWithoutResults'
import { useAllResultsDuplicates } from './hooks/useAllResultsDuplicates'
import { useAllPartsWithoutNumber } from './hooks/useAllPartsWithoutNumber'

interface OldGrowLinesDeliveryDialogProps {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
}

export function OldGrowLinesDeliveryDialog({
  isOpen,
  setIsOpen
}: OldGrowLinesDeliveryDialogProps) {
  const { data } = useGetDemand()
  const { deliveryDemand } = useDeliveryDemand({
    onSuccess: () => {
      setIsOpen(false)
    }
  })
  const { userList, demandName, treatment, id: demandId } = data!

  const reviewers = userList.map((reviewer) => {
    return { ...reviewer, files: reviewer.files.grow_lines ?? [] }
  })

  const allFilesPerReviewers = useAllFilesPerReviewers(reviewers)

  const allPartsOfReviewers = useAllPartsOfReviewers(reviewers)
  const allPartsWithoutNumber = useAllPartsWithoutNumber(reviewers)
  const allPartsWithoutResults = useAllPartsWithoutResults(allPartsOfReviewers)
  const allResultsDuplicates = useAllResultsDuplicates(allPartsOfReviewers)

  const lengthOfWithoutResults = allPartsWithoutResults.length
  const lengthOfDuplicatedFiles = allResultsDuplicates.length
  const lengthOfPartsWithoutNumber = allPartsWithoutNumber.flatMap(
    (reviewer) => reviewer.parts
  ).length

  if (
    !!lengthOfDuplicatedFiles ||
    !!lengthOfPartsWithoutNumber ||
    !!lengthOfWithoutResults ||
    !reviewers.length
  ) {
    return (
      <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
        <div className=" flex h-[432px] w-[603px] flex-col">
          <div className="flex w-full flex-row items-start justify-between gap-5 px-6 pt-6 text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface">
            <div className="flex w-full items-center justify-center pl-11">
              <MdOutlineErrorOutline size={24} />
            </div>
            <DialogRadix.Close title="Fechar">
              <CloseIcon size={24} className="" />
            </DialogRadix.Close>
          </div>
          <h1 className="my-4 text-center font-mono text-titleLarge text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface">
            Não é possível entregar a demanda
          </h1>
          <span className="mb-4 text-center font-mono text-bodyMedium text-cromai-m3-sys-light-on-surface-variant dark:text-cromai-m3-sys-dark-on-surface-variant">
            Verifique os arquivos de resultados.
          </span>
          {reviewers.length === 0 && (
            <p className=" flex items-center justify-center font-mono text-bodyLarge text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface">
              Nenhum responsável atribuidos demanda.
            </p>
          )}
          <ScrollArea.Root className="mb-auto overflow-hidden px-6">
            <ScrollArea.Viewport className="size-full border-inherit">
              <div className="flex w-full flex-col gap-2">
                <Accordion.Root>
                  {!!lengthOfWithoutResults && (
                    <AccordionStyled
                      icon={<MdOutlineExtension size={24} />}
                      value="without-results"
                      title="Partes faltando resultados"
                      labelNumber={lengthOfWithoutResults}
                    >
                      <div className="mb-4 grid w-full grid-cols-3 gap-1 font-mono">
                        {allPartsWithoutResults.map((item) => {
                          return (
                            <Fragment key={item.partId}>
                              <strong className="text-labelLarge">
                                Parte {item.partNumber}
                              </strong>
                              <span className="text-bodyMedium">
                                {item.reviewer}
                              </span>
                              <span className="text-bodyMedium">
                                {!item.files.planting_failure &&
                                !item.files.reconstruction_lines ? (
                                  'Falta ambos'
                                ) : (
                                  <>
                                    {!item.files.planting_failure &&
                                      'Falta falha'}
                                    {!item.files.reconstruction_lines &&
                                      'Falta reconstituição'}
                                  </>
                                )}
                              </span>
                            </Fragment>
                          )
                        })}
                      </div>
                    </AccordionStyled>
                  )}
                  {!!lengthOfDuplicatedFiles && (
                    <AccordionStyled
                      icon={<MdOutlineExtension size={24} />}
                      value="parts-duplicated"
                      title="Partes com resultados duplicados "
                      labelNumber={lengthOfDuplicatedFiles}
                    >
                      <div className="mb-4 grid w-full grid-cols-3 gap-1 font-mono">
                        {allResultsDuplicates.map((item) => {
                          return (
                            <Fragment key={item.partId}>
                              <strong className="text-labelLarge">
                                Parte {item.partNumber}
                              </strong>
                              <span className="text-bodyMedium">
                                {item.reviewer}
                              </span>
                              <span className="text-bodyMedium">
                                {item.files.planting_failure &&
                                item.files.reconstruction_lines ? (
                                  'Inseriu ambos'
                                ) : (
                                  <>
                                    {item.files.planting_failure &&
                                      'Inseriu falha'}
                                    {item.files.reconstruction_lines &&
                                      'Inseriu reconstituição'}
                                  </>
                                )}
                              </span>
                            </Fragment>
                          )
                        })}
                      </div>
                    </AccordionStyled>
                  )}
                  {!!lengthOfPartsWithoutNumber && (
                    <AccordionStyled
                      icon={<MdOutlineExtension size={24} />}
                      value="parts-missing-resultss"
                      title="Resultados sem partes associadas"
                      labelNumber={lengthOfPartsWithoutNumber}
                    >
                      <div className="mb-4 flex w-full flex-col gap-1 font-mono">
                        {allPartsWithoutNumber.map((item) => {
                          return (
                            <div
                              key={item.reviewer}
                              className="flex w-full justify-between"
                            >
                              <strong className="text-labelLarge">
                                {item.reviewer}
                              </strong>
                              <span className="text-bodyMedium">
                                {Number(item.parts?.length) > 1
                                  ? `${item.parts?.length} partes`
                                  : `${item.parts?.length} parte`}
                              </span>
                            </div>
                          )
                        })}
                      </div>
                    </AccordionStyled>
                  )}
                </Accordion.Root>
              </div>
            </ScrollArea.Viewport>
            <ScrollArea.Scrollbar
              orientation="vertical"
              className="mr-2 w-1 py-2"
            >
              <ScrollArea.Thumb className="w-1 rounded-full bg-cromai-m3-sys-light-outline dark:bg-cromai-m3-sys-dark-outline " />
            </ScrollArea.Scrollbar>
          </ScrollArea.Root>

          <div className="mt-6 flex justify-end px-6 pb-6">
            <DialogRadix.Close asChild>
              <Button>Cancelar</Button>
            </DialogRadix.Close>
          </div>
        </div>
      </Dialog>
    )
  }

  return (
    <Dialog isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className=" flex h-[432px] w-[603px] flex-col">
        <div className="flex w-full flex-row items-start justify-between gap-5 px-6 pt-6 text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface">
          <h1 className="font-mono text-bodyLarge ">
            Você tem certeza que deseja entregar a demanda{' '}
            <strong>{demandName}?</strong>
          </h1>
          <DialogRadix.Close title="Fechar">
            <CloseIcon size={24} className="" />
          </DialogRadix.Close>
        </div>
        <p className="mb-6 px-6 font-mono text-bodyMedium text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface">
          Os arquivos serão compactados e enviados.
        </p>
        {reviewers.length === 0 && (
          <p className=" flex items-center justify-center font-mono text-bodyLarge text-cromai-m3-sys-light-on-surface dark:text-cromai-m3-sys-dark-on-surface">
            Nenhum responsável atribuidos demanda.
          </p>
        )}
        <ScrollArea.Root className="mb-auto overflow-hidden px-6">
          <ScrollArea.Viewport className="size-full border-inherit">
            <div className="flex w-full flex-col gap-2">
              <Accordion.Root>
                {allFilesPerReviewers.map((reviewer) => (
                  <AccordionStyled
                    key={reviewer.reviewerName}
                    icon={<MdOutlineExtension size={24} />}
                    value="all-resultes-per-reviewer"
                    title={reviewer.reviewerName}
                    labelNumber={reviewer.parts.length}
                  >
                    <div className="w-full">
                      <Accordion.Root key={`${reviewer.reviewerName}-files`}>
                        {reviewer.parts.map((part) => (
                          <Accordion.Item
                            key={`${reviewer.reviewerName}${part.partNumber}`}
                            value={`${reviewer.reviewerName}${part.partNumber}`}
                          >
                            <Accordion.Header className="flex w-full items-center justify-between">
                              <span className="mr-auto text-labelMedium text-cromai-m3-sys-light-on-background dark:text-cromai-m3-sys-dark-on-background">
                                parte {part.partNumber}
                              </span>
                            </Accordion.Header>
                            <Accordion.Content>
                              <div className="flex w-full flex-col">
                                {part.files.map((file) => (
                                  <span
                                    className="px-3 py-2 font-mono text-labelSmall text-cromai-m3-sys-light-on-surface-variant dark:text-cromai-m3-sys-dark-on-surface-variant "
                                    key={`${reviewer.reviewerName}${part.partNumber}${file}`}
                                  >
                                    {file}
                                  </span>
                                ))}
                              </div>
                            </Accordion.Content>
                          </Accordion.Item>
                        ))}
                      </Accordion.Root>
                    </div>
                  </AccordionStyled>
                ))}
              </Accordion.Root>
            </div>
          </ScrollArea.Viewport>
          <ScrollArea.Scrollbar
            orientation="vertical"
            className="mr-2 w-1 py-2"
          >
            <ScrollArea.Thumb className="w-1 rounded-full bg-cromai-m3-sys-light-outline dark:bg-cromai-m3-sys-dark-outline " />
          </ScrollArea.Scrollbar>
        </ScrollArea.Root>

        <div className="mt-6 flex justify-between px-6 pb-6">
          <DialogRadix.Close asChild>
            <Button variant="outlined">Cancelar</Button>
          </DialogRadix.Close>
          <Button
            className="w-24"
            loading={deliveryDemand.isLoading}
            onClick={() => deliveryDemand.mutate({ demandId, treatment })}
          >
            Entregar
          </Button>
        </div>
      </div>
    </Dialog>
  )
}
