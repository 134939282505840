import { apiAthenas } from '@/services/api/apiAthenas'

type RemoveSessionDurationProps = {
  demandId: string
  partId: string
  fileType: string
  recordId: string
}
export async function removeSessionDuration({
  demandId,
  partId,
  fileType,
  recordId
}: RemoveSessionDurationProps) {
  await apiAthenas.delete(
    `demands/${demandId}/parts/${partId}/file/${fileType}/time/${recordId}`
  )
}
