import { useSearchParams } from 'react-router-dom'

export function useSearchParamsValues() {
  const [searchParams] = useSearchParams()

  const types = searchParams.getAll('type')
  const late = searchParams.get('late') ?? undefined
  const tomorrow = searchParams.get('tomorrow') ?? undefined
  const today = searchParams.get('today') ?? undefined
  const startDate = searchParams.get('startDate') ?? undefined
  const endDate = searchParams.get('endDate') ?? undefined
  const usersId = searchParams.getAll('userId').map(Number)
  const status = searchParams.getAll('status')
  const companiesId = searchParams.getAll('companyId').map(Number)
  const qa = searchParams.get('qa') ?? undefined
  const mosaic = searchParams.get('mosaic') ?? undefined

  const action = searchParams.get('action') ?? undefined
  const initialValue = searchParams.get('initialValue') ?? undefined

  return {
    types,
    companiesId,
    usersId,
    startDate,
    endDate,
    status,
    qa,
    mosaic,
    late,
    today,
    tomorrow,
    action,
    initialValue
  }
}
